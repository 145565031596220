import http from 'utils/http';
import config from 'utils/config';

const resetPassword = (guid, password) => {
	const { AUTHORISATION_SERVER_URL } = config();
	const uri = `${AUTHORISATION_SERVER_URL}/service/account/password-reset/${guid}`;

	return http.post(uri, { data: { password }, withCredentials: true }).then((res) => res.data);
};

export default resetPassword;
