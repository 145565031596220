import React from 'react';

import WissoInterrupt from './assets/wisso_interrupt.svg';
import WissoMemorable from './assets/wisso_memorable.svg';
import WissoSecurity from './assets/wisso_security.svg';

const SingleSignOnInfo = () => {
	return (
		<section>
			<div className="mt-4 mb-6">
				<a className="font-bold text-[#3366CC] underline hover:text-[#3366CC]" href="/">
					Back
				</a>
			</div>
			<h1 className="mb-5 text-3xl">What is single sign-on? (SSO)</h1>
			<p className="mb-10">
				Single sign-on (SSO) is an authentication tool that lets you securely access
				multiple applications and services using just one set of credentials. Put simply,
				our SSO feature means you can log in to your BrightHR services in one go, with
				details you’re already familiar with. Just link a Microsoft, Google or Apple
				account, sign in once, then move between Bright applications in a single click.
			</p>
			<p className="mb-8 text-2xl font-bold">Use single sign-on (SSO) and get:</p>
			<div className="flex flex-col py-6 text-center md:flex-row md:text-left md:items-center">
				<div className="order-2 md:w-1/2 md:order-1">
					<h2 className="text-xl font-bold text-neutral-700 md:text-2xl">
						Fewer interruptions
					</h2>
					<p>Work longer sessions without stopping for sign-in prompts. </p>
				</div>
				<div className="order-1 md:w-1/2 md:order-2">
					<img
						className="block w-56 mx-auto mb-12 md:w-72 lg:w-auto md:mb-0"
						src={WissoInterrupt}
						alt=""
					/>
				</div>
			</div>
			<div className="flex flex-col py-6 text-center md:flex-row md:text-left md:items-center">
				<div className="order-2 md:w-1/2">
					<h2 className="text-xl font-bold text-neutral-700 md:text-2xl">
						One memorable email and password
					</h2>
					<p>Access your Bright account with a login you already know.</p>
				</div>
				<div className="order-1 md:w-1/2">
					<img
						className="block mx-auto mb-12 md:w-72 lg:w-auto md:mb-0"
						src={WissoMemorable}
						alt=""
					/>
				</div>
			</div>
			<div className="flex flex-col py-6 text-center md:flex-row md:text-left md:items-center">
				<div className="order-2 md:w-1/2 md:order-1">
					<h2 className="text-xl font-bold text-neutral-700 md:text-2xl">
						Better login security
					</h2>
					<p>Double down on authentication by linking an account you trust.</p>
				</div>
				<div className="order-1 md:w-1/2 md:order-2">
					<img
						className="block w-56 mx-auto mb-12 md:w-72 lg:w-auto md:mb-0"
						src={WissoSecurity}
						alt=""
					/>
				</div>
			</div>
			<p className="my-8">
				Got a question about your Microsoft, Google or Apple accounts? Please contact their
				respective call centres to speak to their experts.
			</p>
		</section>
	);
};

export default SingleSignOnInfo;
