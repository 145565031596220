import React from 'react';
import { Link } from 'react-router-dom';
import { event } from 'utils/GA';

const SuccessfulUpdate = () => {
	return (
		<div className="p-4 m-6 lg:m-12">
			<div
				className="flex justify-center text-2xl font-semibold text-primary-400"
				data-testid="success-message"
			>
				Password changed!
			</div>
			<div className="flex justify-center mt-8 mb-12 text-lg">
				Please be aware that other services provided by Bright will be affected by this
				change.
			</div>
			<Link
				to="/security"
				onClick={() => {
					event({
						category: 'Change password',
						action: 'Back to accounts page',
					});
				}}
				className="text-lg font-semibold no-underline lg:p-8"
			>
				&larr; Back to Bright Account
			</Link>
		</div>
	);
};

export default SuccessfulUpdate;
