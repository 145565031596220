import React, { useState, useEffect } from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import Button from '@brighthr/component-button';
import getSecret from 'services/2fa/setup';
import verifyCode from 'services/2fa/verify';
import BottomBar from './components/bottomBar';
import Register from './register';
import Done from './done';
import Verify from './verify';
import Layout from '../../layouts/Enable2fa';

export default () => {
	const [secret, setSecret] = useState(null);
	const [code, setCode] = useState('');
	const [validationMessage, setValidationMessage] = useState();
	const [recoveryCodes, setRecoveryCodes] = useState([]);
	const [checked, setChecked] = useState(false);
	const [step, setStep] = useState(1);

	useEffect(() => {
		getSecret().then((resp) => {
			if (resp.secret) {
				setSecret(resp.secret);
			} else {
				setStep(0);
			}
		});
	}, []);

	return (
		<Layout step={step}>
			{step === 0 && (
				<>
					<div className="mb-8">Already Registered</div>
					<div className="w-40">
						<LinkButton outlineButton text="Go back" href="/" />
					</div>
				</>
			)}
			{secret !== null && step === 1 && (
				<>
					<Register secret={secret} />
					<BottomBar cancel>
						<Button onClick={() => setStep(2)} text="Next" />
					</BottomBar>
				</>
			)}
			{step === 2 && (
				<>
					<Verify
						code={code}
						setCode={(cd) => {
							setCode(cd.substring(0, 6));
							setValidationMessage(undefined);
						}}
						validation={validationMessage}
					/>
					<BottomBar cancel>
						<Button
							disabled={code.length !== 6}
							onClick={() =>
								verifyCode(code)
									.then(({ recoveryCodes: rc }) => {
										setRecoveryCodes(rc);
										setStep(3);
									})
									.catch(() => setValidationMessage('The code does not match'))
							}
							text="Next"
						/>
					</BottomBar>
				</>
			)}
			{step === 3 && (
				<>
					<Done recoveryCodes={recoveryCodes} />
					<BottomBar>
						<div className="flex">
							<input
								type="checkbox"
								checked={checked}
								onChange={() => setChecked(!checked)}
								className="w-5 h-5 mr-2"
							/>
							Yes, I’ve downloaded or printed my recovery codes
						</div>

						{checked ? (
							<LinkButton disabled={checked} text="Finish" href="/" />
						) : (
							<Button disabled text="Finish" />
						)}
					</BottomBar>
				</>
			)}
		</Layout>
	);
};
