import http from 'utils/http';
import config from 'utils/config';

const { ACCOUNT_API } = config();

export const listDelegates = () => {
	const url = `${ACCOUNT_API}/v1/account/delegation`;

	return http.get(url).then((res) => res.data);
};

export const addDelegate = (href, email) => {
	const url = `${ACCOUNT_API}${href}`;

	return http.post(url, { data: { email } }).then((res) => res.data);
};

export const deleteDelegate = (href) => {
	const url = `${ACCOUNT_API}${href}`;

	return http.delete(url).then((res) => res.data);
};
