import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import TwoFactorAuthReport from 'pages/twoFactorAuthReport';
import Account from '../../pages/account';
import Authenticator from '../../pages/authenticator';
import ContractExpired from '../../pages/Errors/ContractExpired';
import SingleSignOn from '../../pages/singleSignOn';
import ChangePassword from '../../pages/password/changePassword';
import ManageDelegation from '../../pages/delegation';

const Routes = [
	<Route exact path="/sso" component={SingleSignOn} />,
	<Route exact path="/sso/confirm" component={SingleSignOn} />,
	<Route path="/authenticator" component={Authenticator} />,
	<Route path="/security" component={Account} />,
	<Route path="/2fa-report" component={TwoFactorAuthReport} />,
	<Route path="/subscription-expired" component={ContractExpired} />,
	<Route path="/change-password" component={ChangePassword} />,
	<Route path="/manage-delegation" component={ManageDelegation} />,
	<Redirect exact from="/" to="/security" />,
];

export default Routes;
