/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useToast } from '@brighthr/component-toast';
import Breadcrumb from '@brighthr/component-breadcrumb';
import LinkButton from '@brighthr/component-linkbutton';

import { listDelegates, addDelegate, deleteDelegate } from 'services/delegation';
import { event } from 'utils/GA';

import Layout from '../../layouts/AccountHeader';
import ListDelegates from './ListDelegates';
import AddDelegateModal from './AddDelegateModal';
import DeleteDelegateModal from './DeleteDelegateModal';

const ga = (action) => {
	const eventArgs = {
		category: 'Delegation',
		action,
	};

	return event(eventArgs);
};

const ManageDelegation = () => {
	const [showAddDelegateModal, setShowAddDelegateModal] = useState(false);
	const [showDeleteDelegateModal, setShowDeleteDelegateModal] = useState(false);
	const [doesDelegateExist, setDoesDelegateExist] = useState(false);
	const [isNotConsultant, setIsNotConsultant] = useState(false);
	const [isAddFormLoading, setIsAddFormLoading] = useState(false);
	const [isDeleteFormLoading, setIsDeleteFormLoading] = useState(false);
	const [isDeleteDelegateError, setIsDeleteDelegateError] = useState(false);
	const [deleteDelegateInfo, setDeleteDelegateInfo] = useState({});

	const { data, isLoading, isError, refetch: refetchDelegateList } = useQuery(
		['get', 'delegates'],
		() => listDelegates()
	);

	const { createToast } = useToast();

	const closeAddDelegateModal = () => {
		setShowAddDelegateModal(false);
		setDoesDelegateExist(false);
		setIsNotConsultant(false);
	};

	const closeDeleteDelegateModal = () => {
		setShowDeleteDelegateModal(false);
	};

	const handleDeleteSubmit = () => {
		setIsDeleteFormLoading(true);

		deleteDelegate(deleteDelegateInfo.deleteUrl)
			?.then(() => {
				setShowDeleteDelegateModal(false);
				createToast({
					text: 'Delegate user removed',
					type: 'success',
				});
			})
			?.catch(() => {
				setIsDeleteDelegateError(true);
			})
			?.finally(() => {
				setIsDeleteFormLoading(false);
				refetchDelegateList();
			});
	};

	const handleAddSubmit = (values) => {
		setIsAddFormLoading(true);

		addDelegate(data?._links?.['create-form'].href, values.finalEmail)
			.then(() => {
				setShowAddDelegateModal(false);
				createToast({
					text: 'Delegate user added',
					type: 'success',
				});
			})
			.catch(({ response: { status } }) => {
				if (status === 409) {
					setDoesDelegateExist(true);
				} else if (status === 404) {
					setIsNotConsultant(true);
				}
			})
			.finally(() => {
				setIsAddFormLoading(false);
				refetchDelegateList();
			});
	};

	const delegateInfo = (name, deleteUrl) => {
		setDeleteDelegateInfo({ name, deleteUrl });
	};

	const addModalProps = {
		isOpen: showAddDelegateModal,
		closeModal: closeAddDelegateModal,
		domains: data?._embedded?.['delegate-domains'],
		handleAddSubmit,
		doesDelegateExist,
		setDoesDelegateExist,
		isNotConsultant,
		setIsNotConsultant,
		isAddFormLoading,
		ga,
	};

	const deleteModalProps = {
		isOpen: showDeleteDelegateModal,
		closeModal: closeDeleteDelegateModal,
		deleteDelegateInfo,
		handleDeleteSubmit,
		isDeleteFormLoading,
		isDeleteDelegateError,
		ga,
	};

	const listDelegateProps = {
		setShowDeleteDelegateModal,
		data,
		isLoading,
		isError,
		delegateInfo,
		ga,
	};

	return (
		<Layout>
			<div className="m-4 md:m-0">
				<Breadcrumb
					currentPage="Delegation"
					routes={[
						{
							href: '/',
							name: 'My account',
						},
					]}
					size="base"
				/>
				<div className="flex justify-between my-8">
					<h2 className="inline-block leading-8 align-middle text-neutral-700">
						Delegation
					</h2>
					<LinkButton
						color="accent"
						size="base"
						text="Add a delegate"
						onClick={() => {
							ga('Add a delegate');
							setShowAddDelegateModal(true);
						}}
					/>
				</div>
			</div>
			<ListDelegates {...listDelegateProps} />
			<AddDelegateModal {...addModalProps} />
			<DeleteDelegateModal {...deleteModalProps} />
		</Layout>
	);
};

export default ManageDelegation;
