import React from 'react';
import SingleSignOnTile from 'components/SingleSignOnTile';
import TwoFactorAuth from '../TwoFactorAuth';
import PasswordManagement from '../PasswordManagement';

const SecuritySetting = () => {
	return (
		<>
			<div className="mb-4">
				<PasswordManagement />
			</div>
			<div className="mb-4">
				<SingleSignOnTile />
			</div>
			<div className="mb-4">
				<TwoFactorAuth />
			</div>
		</>
	);
};

export default SecuritySetting;
