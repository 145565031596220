import getToken from '../getToken';

const getEnv = (links) => {
	const locale = getToken().locale?.slice(3) || 'gb';
	return links[locale];
};

export default () => ({
	BLIP_DOTCOM_LINK: getEnv({
		gb: 'https://www.brighthr.com/blip-clocking-in-app/',
		ca: 'https://www.brighthr.com/ca/blip/',
		ie: 'https://www.brighthr.com/ie/blip-clocking-in-app/',
		au: 'https://www.brighthr.com/au/blip/',
		nz: 'https://www.brighthr.com/nz/blip/',
	}),

	POP_DOTCOM_LINK: getEnv({
		gb: 'https://www.brighthr.com/pop-expense-tracker-app/',
		ca: 'https://www.brighthr.com/ca/expense-tracker-app/',
		ie: 'https://www.brighthr.com/ie/pop-expense-tracker-app/',
		au: 'https://www.brighthr.com/au/expense-tracker-app/',
		nz: 'https://www.brighthr.com/nz/expense-tracker-app/',
	}),

	BRIGHTADVICE_DOTCOM_LINK: getEnv({
		gb: 'https://www.brighthr.com/brightadvice-employment-law-advice/',
		ca: 'https://www.brighthr.com/ca/employment-relations/',
		ie: 'https://www.brighthr.com/ie/brightadvice-employment-law-advice/',
		au: 'https://www.brighthr.com/au/brightadvice-relations-law-advice/',
	}),

	BRIGHTADVICE_SUBHEADING: getEnv({
		gb: 'HR and Employment law advice',
		ca: 'HR and Employment relations',
		ie: 'HR and Employment law advice',
		au: 'HR and Employment relations advice',
	}),

	SUPPORT_PHONE: getEnv({
		gb: '0844 892 3927',
		ca: '1-833-247-3658',
		ie: '1800 279 841',
		au: '1 300 029 198',
		nz: '0800 675 707',
	}),

	SUPPORT_EMAIL: getEnv({
		gb: 'support@brighthr.com',
		ca: 'bhrsupport@brighthr.ca',
		ie: 'support@brighthr.com',
		au: 'support@brighthr.com.au',
		nz: 'brighthr@employsure.co.nz',
	}),
});
