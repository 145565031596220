import React from 'react';

export default ({ fillColour }) => {
	return (
		<svg
			width="215"
			height="51"
			viewBox="0 0 215 51"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_3835_162906)">
				<path
					d="M35.5408 21.9181C35.1424 20.5237 33.6484 19.9261 31.0587 20.1253L30.1623 20.2249C28.5686 20.3245 26.477 20.5237 24.2857 20.8225C31.5567 16.1412 43.8078 9.86623 43.9074 9.86623L43.509 8.27259C43.3098 7.47577 42.3138 7.17697 41.4173 7.57538C39.0269 8.77061 27.2738 15.1452 21.5965 18.8304C25.6802 9.46782 29.4651 1.79844 29.4651 1.59924L27.7718 1.20083C26.975 1.00162 25.8794 1.49963 25.481 2.39606C24.0865 5.28452 21.0985 11.7587 17.8116 19.1292C15.9191 16.1412 13.5287 13.6511 12.1343 12.2567C11.5366 11.6591 10.441 11.8583 9.7438 12.5555L8.34936 13.9499C8.34936 13.9499 12.3335 17.5356 14.9231 21.7189C11.1382 22.2169 7.55254 22.7149 4.86328 23.3125C3.86726 23.5118 3.17004 24.4082 3.17004 25.3046L3.36925 27.0974C5.4609 26.4998 8.64817 26.0018 12.2339 25.5038C9.04658 27.8943 5.7597 30.6831 2.37322 33.7708C1.5764 34.468 1.4768 35.464 1.97481 36.0616L3.07044 37.2569C6.85533 33.7708 10.5406 30.6831 14.1263 27.9939C11.6362 34.0696 9.44499 39.9461 8.24976 44.4282L8.15016 44.6274C7.75175 46.0219 9.24578 46.8187 10.5406 45.9223L10.7398 45.8227C11.1382 45.5239 21.7957 38.2529 27.7718 32.8744C33.9472 27.2966 36.238 24.1094 35.5408 21.9181ZM25.6802 30.4839C22.0945 33.6712 16.6164 37.7549 12.9311 40.4442C14.5247 35.7628 16.716 30.1851 19.0068 24.707C23.0905 24.3086 26.975 23.9102 29.6643 23.711L30.5607 23.6114C31.3575 23.5118 31.8555 23.6114 32.0547 23.6114C31.8555 24.1094 30.8595 25.9022 25.6802 30.4839Z"
					fill={fillColour ?? '#0057AD'}
				/>
				<path
					d="M60.9721 12.4653C58.476 12.4653 54.6819 13.7701 53.2841 16.1792V3.4314L50.3886 3.73253C48.7911 3.93328 47.4932 5.33854 47.4932 7.04494V36.2544H52.8847L53.2841 33.0424C55.0813 35.8529 57.9768 36.7563 60.7724 36.7563C67.6616 36.7563 72.7536 32.2394 72.7536 24.6108C72.8535 16.6811 67.8613 12.4653 60.9721 12.4653ZM60.4728 31.4364C56.8785 31.4364 53.6835 28.8266 53.6835 24.6108C53.6835 20.5957 56.7786 17.8856 60.4728 17.8856C64.1671 17.8856 67.0625 20.4953 67.0625 24.6108C67.0625 28.6258 64.1671 31.4364 60.4728 31.4364Z"
					fill={fillColour ?? '#0057AD'}
				/>
				<path
					d="M75.2493 16.681C75.2493 14.9746 76.5472 13.5694 78.1447 13.3686L80.541 13.0675L80.9403 15.6773C82.7375 12.7664 85.1338 12.3649 87.53 12.3649C89.9263 12.3649 92.2227 13.2683 93.5207 14.5731L90.9247 19.592C89.7266 18.5882 88.6283 18.0863 86.7313 18.0863C83.736 18.0863 80.9403 19.6923 80.9403 23.9081V36.154H75.1494V16.681H75.2493Z"
					fill={fillColour ?? '#0057AD'}
				/>
				<path
					d="M101.709 6.54303C101.709 10.9596 94.9194 10.9596 94.9194 6.54303C94.9194 2.12648 101.709 2.0261 101.709 6.54303Z"
					fill={fillColour ?? '#0057AD'}
				/>
				<path
					d="M101.209 12.967L98.3134 13.2682C96.7159 13.4689 95.418 14.8742 95.418 16.5806V36.2543H101.209V12.967V12.967Z"
					fill={fillColour ?? '#0057AD'}
				/>
				<path
					d="M127.468 24.0085C127.468 21.6999 126.869 19.592 124.972 17.1829L127.668 13.6698L125.471 12.3649C124.173 11.6622 122.575 11.9634 121.577 13.1679L121.178 13.6698C119.68 12.5656 117.284 12.2645 115.586 12.2645C109.496 12.2645 103.605 16.1792 103.605 24.0085C103.605 31.2356 108.398 35.6522 115.586 35.6522C119.68 35.6522 121.777 37.4589 121.777 40.1691C121.777 43.08 118.482 44.3849 115.487 44.3849C112.092 44.3849 108.897 42.4777 108.997 39.2657H103.605C103.505 45.9909 107.898 50.0059 115.586 50.0059C121.477 50.0059 127.568 46.7939 127.568 40.2695C127.568 38.0612 126.769 34.548 122.176 32.7412C125.671 31.0349 127.468 28.2243 127.468 24.0085ZM115.586 30.4326C111.992 30.4326 108.997 28.1239 108.997 24.0085C108.997 19.6923 111.992 17.5844 115.586 17.5844C119.181 17.5844 122.076 20.0938 122.076 24.0085C122.076 28.0236 119.181 30.4326 115.586 30.4326Z"
					fill={fillColour ?? '#0057AD'}
				/>
				<path
					d="M135.755 16.1792C137.852 13.4691 140.348 12.6661 143.043 12.6661C149.633 12.6661 152.628 17.0826 152.628 23.9082V36.1541H146.837V24.0086C146.837 19.7928 144.641 17.986 141.546 17.986C138.151 17.986 135.755 20.8969 135.755 24.3097V36.1541H129.964V6.84426C129.964 5.13786 131.262 3.73259 132.859 3.53184L135.755 3.23071V16.1792V16.1792Z"
					fill={fillColour ?? '#0057AD'}
				/>
				<path
					d="M157.92 10.1566C157.92 8.45024 159.218 7.04497 160.815 6.84422L163.711 6.54309V13.0675H170.101V17.986H163.611V28.0236C163.611 30.2319 164.809 31.336 166.606 31.336C167.505 31.336 168.503 31.0349 169.402 30.6334L170.999 35.5518C169.302 36.2544 168.004 36.4552 166.207 36.5556C161.115 36.7563 157.82 33.8454 157.82 28.124V18.0864H153.427V13.1679H157.82V10.1566H157.92Z"
					fill={fillColour ?? '#0057AD'}
				/>
			</g>
			<path
				d="M174 16.8842L177.075 16.2535C177.206 17.0287 177.791 17.4163 178.829 17.4163C179.223 17.4163 179.531 17.344 179.755 17.1995C179.991 17.0418 180.11 16.8513 180.11 16.6279C180.11 16.2601 179.781 16.017 179.124 15.8987L177.114 15.5046C175.196 15.1498 174.237 14.1907 174.237 12.6271C174.237 11.6154 174.631 10.8074 175.419 10.203C176.207 9.58548 177.232 9.27672 178.494 9.27672C179.702 9.27672 180.701 9.53293 181.489 10.0453C182.291 10.5578 182.79 11.2476 182.987 12.1147L180.11 12.6863C180.057 12.3578 179.88 12.0819 179.577 11.8585C179.288 11.6352 178.914 11.5235 178.454 11.5235C178.06 11.5235 177.771 11.6023 177.587 11.76C177.416 11.9045 177.331 12.0819 177.331 12.2921C177.331 12.6469 177.58 12.8768 178.08 12.9819L180.405 13.4549C181.312 13.652 181.995 14.0133 182.455 14.5389C182.915 15.0513 183.145 15.6819 183.145 16.4309C183.145 17.4951 182.731 18.3163 181.903 18.8944C181.075 19.4725 179.991 19.7616 178.651 19.7616C177.403 19.7616 176.352 19.5251 175.498 19.0521C174.657 18.5659 174.158 17.8433 174 16.8842Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M195.219 9.57235V19.466H192.066V18.6776C191.264 19.4003 190.239 19.7616 188.991 19.7616C187.651 19.7616 186.508 19.2754 185.562 18.3032C184.629 17.3177 184.163 16.0564 184.163 14.5192C184.163 12.995 184.629 11.7403 185.562 10.7548C186.508 9.76943 187.651 9.27672 188.991 9.27672C190.239 9.27672 191.264 9.63804 192.066 10.3607V9.57235H195.219ZM189.642 17.0812C190.298 17.0812 190.863 16.8447 191.336 16.3717C191.823 15.8856 192.066 15.2681 192.066 14.5192C192.066 13.7834 191.823 13.1724 191.336 12.6863C190.863 12.2001 190.298 11.9571 189.642 11.9571C188.945 11.9571 188.374 12.2001 187.927 12.6863C187.48 13.1593 187.257 13.7702 187.257 14.5192C187.257 15.2812 187.48 15.8987 187.927 16.3717C188.374 16.8447 188.945 17.0812 189.642 17.0812Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M201.202 9.57235H203.921V12.0753H201.202V19.466H198.068V12.0753H196.649V9.57235H198.068V8.80372C198.068 7.64749 198.429 6.72777 199.152 6.04454C199.888 5.34818 200.853 5 202.049 5C202.719 5 203.37 5.13796 204 5.41388L203.33 7.85772C203.015 7.7526 202.732 7.70005 202.483 7.70005C202.102 7.70005 201.793 7.81173 201.556 8.03509C201.32 8.25845 201.202 8.56065 201.202 8.94168V9.57235Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M214.622 14.5192V15.3272H207.369C207.501 15.9184 207.757 16.3849 208.138 16.7265C208.519 17.0681 208.972 17.2389 209.498 17.2389C210.483 17.2389 211.186 16.8579 211.607 16.0958L214.405 16.6674C213.985 17.6922 213.348 18.4674 212.494 18.993C211.653 19.5054 210.654 19.7616 209.498 19.7616C208.039 19.7616 206.804 19.2754 205.793 18.3032C204.781 17.3177 204.275 16.0564 204.275 14.5192C204.275 12.995 204.781 11.7403 205.793 10.7548C206.804 9.76943 208.046 9.27672 209.518 9.27672C210.95 9.27672 212.152 9.75629 213.124 10.7154C214.097 11.6746 214.596 12.9425 214.622 14.5192ZM209.518 11.8388C209.058 11.8388 208.631 11.9768 208.237 12.2527C207.842 12.5286 207.573 12.9096 207.429 13.3958H211.508C211.364 12.8834 211.114 12.4958 210.759 12.233C210.404 11.9702 209.991 11.8388 209.518 11.8388Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M176.601 36.4285V22.3792H175.118V36.4285H176.601Z"
				fill={fillColour ?? '#3DB0F7'}
			/>
			<path
				d="M180.932 22.906C180.346 22.906 179.917 23.3743 179.917 23.9012C179.917 24.467 180.346 24.8963 180.932 24.8963C181.517 24.8963 181.966 24.467 181.966 23.9012C181.966 23.3743 181.517 22.906 180.932 22.906ZM181.693 36.4285V26.8086H180.19V36.4285H181.693Z"
				fill={fillColour ?? '#3DB0F7'}
			/>
			<path
				d="M190.562 34.6919C190.075 35.0431 189.47 35.2773 188.923 35.2773C187.85 35.2773 187.148 34.5748 187.148 33.326V28.0574H190.582V26.8086H187.148V23.7451H185.645V26.8086H183.557V28.0574H185.645V33.4235C185.645 35.5505 186.913 36.6822 188.826 36.6822C189.606 36.6822 190.27 36.4676 191.05 35.9212L190.562 34.6919Z"
				fill={fillColour ?? '#3DB0F7'}
			/>
			<path
				d="M201.488 31.3747C201.39 28.5062 199.361 26.5745 196.766 26.5745C194.112 26.5745 191.79 28.5648 191.79 31.6478C191.79 34.7114 194.112 36.6822 196.668 36.6822C198.834 36.6822 200.512 35.6285 201.273 33.6187L199.946 33.3065C199.263 34.848 198.034 35.3748 196.668 35.3748C195.01 35.3748 193.37 34.087 193.234 31.921H201.488V31.3747ZM196.766 27.8818C198.366 27.8818 199.712 28.916 199.985 30.8088H193.292C193.585 28.916 195.205 27.8818 196.766 27.8818Z"
				fill={fillColour ?? '#3DB0F7'}
			/>
			<defs>
				<clipPath id="clip0_3835_162906">
					<rect
						width="170.32"
						height="49.8012"
						fill="white"
						transform="translate(0.680176 0.204712)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
