import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';

export default ({ children, cancel = false }) => {
	return (
		<div className="fixed inset-x-0 bottom-0 flex flex-row items-center justify-between px-6 py-4 bg-primary-50">
			{cancel ? <LinkButton outlineButton text="Exit setup" href="/" /> : <div />}
			{children}
		</div>
	);
};
