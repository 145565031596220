import React, { useState } from 'react';
import LoadingContainer from 'utils/loadingContainer';
import Button from '@brighthr/component-button';
import { Formik } from 'formik';
import { useQuery } from 'react-query';
import { event } from 'utils/GA';
import config from 'utils/config';
import SuccessfulUpdate from './SuccessfulUpdate';
import PasswordFields from '../PasswordFields';
import checkResetPasswordGuid from '../../../services/checkResetPasswordGuid';
import resetPassword from '../../../services/resetPassword';
import ErrorMessage from '../ErrorMessage';
import Layout from '../../../layouts';

const ResetPassword = ({
	match: {
		params: { guid },
	},
	location: { search },
}) => {
	const [successfulUpdate, setSuccessfulUpdate] = useState(false);
	const [resetFailed, setResetFailed] = useState(false);
	const [existingPassword, setExistingPassword] = useState(false);

	const { HR_URL } = config();

	const returnUrl = decodeURIComponent(search.split('=')[1] || HR_URL);

	const { data, isLoading: loading, isError } = useQuery(
		['checkResetPasswordGuid', guid],
		() => checkResetPasswordGuid(guid),
		{
			retry: false,
		}
	);
	const validGuid = isError === false && data?.expired === false;

	if (loading) {
		return <LoadingContainer loading data-testid="loading-spinner" />;
	}

	if (!validGuid) {
		return (
			<ErrorMessage message="The reset password link you have used seems to be invalid or expired." />
		);
	}

	if (resetFailed) {
		return <ErrorMessage message="Something went wrong." />;
	}

	if (existingPassword) {
		return (
			<ErrorMessage
				message="You are unable to reuse your existing password, please try another"
				ButtonComp={() => (
					<Button onClick={() => setExistingPassword(false)} text="Try again" />
				)}
			/>
		);
	}

	if (successfulUpdate) {
		return (
			<Layout>
				<SuccessfulUpdate returnUrl={returnUrl}>Password reset</SuccessfulUpdate>
			</Layout>
		);
	}

	const shouldDisableButton = (validation) =>
		!(
			validation.validation0 &&
			validation.validation1 &&
			validation.validation2 &&
			validation.validation3
		);

	return (
		<Layout>
			<Formik
				initialValues={{
					newpassword: '',
					confirmpassword: '',
					validation: {
						validation0: undefined,
						validation1: undefined,
						validation2: undefined,
					},
				}}
				onSubmit={(values) => {
					resetPassword(guid, values.newpassword)
						.then(() => setSuccessfulUpdate(true))
						.catch(({ response: { status } }) =>
							status === 409 ? setExistingPassword(true) : setResetFailed(true)
						);
					event({
						category: 'Reset password',
						action: 'Reset password',
					});
				}}
			>
				{(formik, { values } = formik) => (
					<div className="flex justify-center">
						<form className="sm:w-2/4" onSubmit={formik.handleSubmit}>
							<h1 className="my-6 mr-6 text-3xl text-neutral-700">
								Reset your password
							</h1>
							<p className="mb-4 leading-6">
								Resetting your password will also affect any other services provided
								by BrightHR such as BLIP, POP, BrightSafe, BrightExchange and
								BrightHR Lightning.
							</p>
							<PasswordFields formik={formik} categoryName="Reset password" />
							<div className="mt-3">
								<Button
									type="submit"
									loading={formik.isSubmitting}
									disabled={
										shouldDisableButton(values.validation) ||
										formik.isSubmitting
									}
									text="Reset password"
								/>
							</div>
						</form>
					</div>
				)}
			</Formik>
		</Layout>
	);
};

export default ResetPassword;
