import React from 'react';
import Layout from '../../layouts';

export default () => {
	return (
		<Layout>
			<div className="border-2 p-5 border-primary-100 rounded items-center">
				<h1 className="text-primary-700 text-2xl font-bold mb-4">
					Oops! Looks like you’re in the wrong place…
				</h1>
				<div className="flex items-center">
					<div className="space-y-3">
						<p>
							You unfortunately don’t have permission to access this product, as it’s
							not supported in your country.
						</p>
						<p>... for now ...</p>
					</div>
				</div>
			</div>
		</Layout>
	);
};
