import config from 'utils/config';

const setup = (provider) => {
	const { AUTHORISATION_SERVER_URL: authServerAPI } = config();
	const uri = `${authServerAPI}/sso/${provider}/setup?returnUrl=${window.location.origin}/sso/confirm?provider=${provider}`;

	return window.location.assign(uri);
};

export default setup;
