// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
	Table,
	TableBody,
	TableHeader,
	TableHeaderCell,
	TableCell,
	TableRow,
	SortableTableHeaderCell,
	sortAlpha,
} from '@brighthr/component-table';
import Xingloader from '@brighthr/component-xingloader';
import Alert from '@brighthr/component-alert';
import Button from '@brighthr/component-button';
import NoRecordsMessage from 'components/NoRecordsMessage';
import MobileView from './MobileView';
import noDelegatesImg from '../assets/noDelegates.png';

const ManageDelegation = ({
	setShowDeleteDelegateModal,
	data,
	isLoading,
	isError,
	delegateInfo,
	ga,
}) => {
	const [sortedDelegateData, setSortedDelegateData] = useState([]);
	const [activeColumn, setActiveColumn] = useState(1);

	useEffect(() => {
		if (data) {
			setSortedDelegateData(data.delegates);
		}
	}, [data]);

	if (isLoading) {
		return (
			<div className="flex justify-center mb-4">
				<Xingloader
					ariaLabel="processing request"
					className="stroke-primary-700"
					size="xxl"
				/>
			</div>
		);
	}

	if (isError) {
		return (
			<Alert
				type="error"
				icon="error"
				title="Error"
				border
				dismiss
				fullWidth
				data-testid="error"
				className="mb-4"
			>
				Something has gone wrong
			</Alert>
		);
	}

	if (sortedDelegateData?.length === 0) {
		return (
			<NoRecordsMessage
				logo={noDelegatesImg}
				text="You haven't set up any delegates for account access yet. Hit the button above to add their details."
			/>
		);
	}

	return (
		<>
			<div className="hidden md:block">
				<Table>
					<TableHeader>
						<TableRow>
							<SortableTableHeaderCell
								activeColumn={activeColumn === 1}
								initialDirection="ASC"
								onSortAscending={() => {
									ga('ASC - Name');
									setSortedDelegateData(sortAlpha('name', sortedDelegateData));
								}}
								onSortDescending={() => {
									ga('DESC - Name');
									setSortedDelegateData(
										sortAlpha('name', sortedDelegateData).reverse()
									);
								}}
								text="Delegate name"
								setActiveColumn={() => setActiveColumn(1)}
							/>
							<SortableTableHeaderCell
								activeColumn={activeColumn === 2}
								initialDirection="ASC"
								onSortAscending={() => {
									ga('ASC - Email');
									setSortedDelegateData(sortAlpha('email', sortedDelegateData));
								}}
								onSortDescending={() => {
									ga('DESC - Email');
									setSortedDelegateData(
										sortAlpha('email', sortedDelegateData).reverse()
									);
								}}
								text="Email"
								setActiveColumn={() => setActiveColumn(2)}
							/>
							<SortableTableHeaderCell
								activeColumn={activeColumn === 4}
								initialDirection="ASC"
								onSortAscending={() => {
									ga('ASC - Added by');
									setSortedDelegateData(sortAlpha('addedBy', sortedDelegateData));
								}}
								onSortDescending={() => {
									ga('DESC - Added by');
									setSortedDelegateData(
										sortAlpha('addedBy', sortedDelegateData).reverse()
									);
								}}
								text="Added by"
								setActiveColumn={() => setActiveColumn(4)}
							/>
							<TableHeaderCell />
						</TableRow>
					</TableHeader>
					<TableBody>
						{sortedDelegateData.map((delegateData) => (
							<TableRow key={delegateData._links.item.href}>
								<TableCell columnName="Delegate name">
									{delegateData.name}
								</TableCell>
								<TableCell columnName="Email">{delegateData.email}</TableCell>
								<TableCell columnName="Added by">{delegateData.addedBy}</TableCell>
								<TableCell>
									<span className="flex justify-center">
										<Button
											outlineButton
											text="Remove delegate"
											onClick={() => {
												ga('Remove delegate');
												setShowDeleteDelegateModal(true);
												delegateInfo(
													delegateData.name,
													delegateData._links.item.href
												);
											}}
										/>
									</span>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<MobileView
				sortedDelegateData={sortedDelegateData}
				delegateInfo={delegateInfo}
				setShowDeleteDelegateModal={setShowDeleteDelegateModal}
			/>
		</>
	);
};

export default ManageDelegation;
