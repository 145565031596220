import React, { useState, forwardRef } from 'react';
import copy from 'copy-to-clipboard';
import fileDownload from 'js-file-download';
import ReactToPrint from 'react-to-print';
import Icon from '@brighthr/component-icon';

const a = document.createElement('a');
let canDownload = false;
if (typeof a.download !== 'undefined') {
	canDownload = true;
}

export default forwardRef(({ recoveryCodes }, ref) => {
	const [copied, setCopied] = useState(false);

	return (
		<>
			<div className="space-y-4 text-center md:text-left">
				<div className="items-center block md:flex">
					<div className="items-center justify-center hidden w-10 h-10 mr-4 rounded-full bg-primary-500 md:flex">
						<Icon iconName="printer" size={24} className="fill-white" />
					</div>
					<div>
						<ReactToPrint
							name="print"
							trigger={() => (
								<button type="button" className="underline text-primary-700">
									Print backup codes
								</button>
							)}
							content={() => ref.current}
						/>
						<p>Print out and store in a safe place</p>
					</div>
				</div>

				{canDownload && (
					<div className="items-center block md:flex">
						<div className="items-center justify-center hidden w-10 h-10 mr-4 rounded-full bg-primary-500 md:flex">
							<Icon iconName="arrow-big-down" size={24} className="fill-white" />
						</div>
						<div>
							<button
								type="button"
								className="underline text-primary-700"
								onClick={() =>
									fileDownload(
										recoveryCodes.join('\n'),
										'BrightHR.recoverycodes.txt'
									)
								}
							>
								Download backup codes
							</button>
							<p>Avoid saving them to your phone</p>
						</div>
					</div>
				)}
				<button
					type="button"
					className="mr-5 underline text-primary-700"
					onClick={() => {
						copy(recoveryCodes.join('\n'));
						setCopied(true);
					}}
				>
					{copied ? 'Copied' : 'Copy codes'}
				</button>
			</div>
		</>
	);
});
