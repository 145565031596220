import React from 'react';
import { Link } from 'react-router-dom';
import { event } from 'utils/GA';
import LinkButton from '@brighthr/component-linkbutton';
import ssoImage from './assets/sso-image.svg';

const ga = (action) =>
	event({
		category: 'My Account',
		action,
	});

const SingleSignOnTile = () => {
	return (
		<div className="border-2 border-primary-100 rounded-2xl">
			<div className="items-center p-5 my-4 space-y-6 md:flex">
				<img src={ssoImage} alt="" className="m-auto" />
				<div className="ml-4">
					<h2 className="inline-block mb-2 mr-2 text-lg font-bold text-neutral-700">
						Single sign-on
					</h2>
					<div className="inline-flex px-2 text-xs tracking-wider rounded-full bg-success-100 text-success-900">
						OPTIONAL
					</div>
					<p>
						Once set up, Single sign-on allows you to log in once and access services
						without re-entering your password every time you log into your Bright
						account.
					</p>
				</div>
				<div className="w-full whitespace-nowrap md:w-auto md:ml-3">
					<LinkButton
						href="/sso"
						text="Settings"
						as={Link}
						onClick={() => ga('Single sign-on Settings')}
					/>
				</div>
			</div>
			<div className="flex justify-start p-4 sm:justify-end bg-primary-50 rounded-b-2xl ">
				<a
					className="font-bold text-[#3366CC] underline hover:text-[#3366CC]"
					href="/security/what-is-sso"
					onClick={() => ga('What is Single sign-on?')}
				>
					What is Single sign-on?
				</a>
			</div>
		</div>
	);
};

export default SingleSignOnTile;
