import React, { useState } from 'react';
import { Formik } from 'formik';
import { Modal, ModalBody, ModalFooter } from '@brighthr/component-modal';
import Button from '@brighthr/component-button';
import FormikSelect from '../FormikUI/FormikSelect';
import { set, clear } from '../../utils/config/config';
import config from '../../utils/config';

const AUTHORISATION_URL = {
	sandbox: 'https://sandbox-login.brighthr.com',
	prod: 'https://login.brighthr.com',
	local: 'https://localhost:44301',
	qa01: 'https://qa01-login.brighthr.com',
	qa02: 'https://qa02-login.brighthr.com',
	qa03: 'https://qa03-login.brighthr.com',
	qa04: 'https://qa04-login.brighthr.com',
};

const HR_REG = {
	prod: 'https://sandbox-api.brighthr.com/',
	qa01: 'https://qa01-api.brighthr.com/',
	qa02: 'https://qa02-api.brighthr.com/',
	qa03: 'https://qa03-api.brighthr.com/',
	qa04: 'https://qa04-api.brighthr.com/',
	qa05: 'https://qa05-api.brighthr.com/',
	qa06: 'https://qa06-api.brighthr.com/',
	qa07: 'https://qa07-api.brighthr.com/',
};

const payload = ({ auth, hr }) => {
	const isQA = auth.includes('qa');
	return {
		AUTHORISATION_SERVER_URL: auth,
		ACCOUNT_API: auth,
		REG_URL: hr,
		GLOBAL_API: isQA
			? 'https://qa-01-api.brighthr.com/v1/'
			: 'https://sandbox-api.brighthr.com/v1/',
	};
};

const EnvSwitch = () => {
	const [open, setOpen] = useState(false);
	const { AUTHORISATION_SERVER_URL, REG_URL } = config();
	return (
		<>
			<button
				type="submit"
				aria-label="Enviroment"
				className="fixed bottom-[20px] left-[20px] bg-gradient-to-r from-primary-500 to-accent-500 p-3 rounded-full text-white"
				onClick={() => setOpen(!open)}
			>
				ENV
			</button>
			{open && (
				<Modal title="ENV" close={() => setOpen(false)}>
					<Formik
						initialValues={{
							auth: AUTHORISATION_SERVER_URL,
							hr: REG_URL,
						}}
						onSubmit={(values) => {
							set(payload(values));
						}}
					>
						{({ submitForm }) => (
							<>
								<ModalBody>
									<h1>AUTH ENV</h1>
									<FormikSelect name="auth">
										{Object.keys(AUTHORISATION_URL).map((key) => {
											return (
												<option value={AUTHORISATION_URL[key]}>
													{key}
												</option>
											);
										})}
									</FormikSelect>
									<h1>HR ENV</h1>
									<FormikSelect name="hr">
										{Object.keys(HR_REG).map((key) => {
											return <option value={HR_REG[key]}>{key}</option>;
										})}
									</FormikSelect>
								</ModalBody>
								<ModalFooter>
									<Button type="submit" text="Submit" onClick={submitForm} />
									<Button type="button" text="Reset" onClick={clear} />
								</ModalFooter>
							</>
						)}
					</Formik>
				</Modal>
			)}
		</>
	);
};

export default EnvSwitch;
