import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import getToken from 'utils/getToken';
import copy from 'copy-to-clipboard';

const splitIntoGroupsOfFour = /[\s\S]{1,4}/g;

export default ({ secret }) => {
	const [copied, setCopied] = useState(false);
	const { email } = getToken();

	return (
		<div className="flex flex-col md:flex-row-reverse">
			<div className="mb-8 font-bold text-center">
				<QRCode
					className="w-48 h-48 mx-auto mb-6 md:w-64 md:h-64"
					value={`otpauth://totp/BrightHR:${email}?secret=${secret}&issuer=BrightHR`}
				/>
				Scan me using your authenticator app
			</div>

			<div className="px-8 text-center md:text-left">
				<div className="mb-5 space-y-1 md:space-y-6 md:mb-16">
					<h2 className="text-2xl text-neutral-700">
						How to set up Two-Factor authentication
					</h2>
					<p>
						<b>Scan the QR code</b> with your authenticator app:
					</p>
					<p>Or enter this code manually:</p>
					<div className="inline-block px-8 py-3 font-mono bg-primary-50">
						{secret.match(splitIntoGroupsOfFour).map((str) => (
							<span key={str} className="mr-4 last:mr-0">
								{str}
							</span>
						))}
					</div>
					<button
						type="button"
						className="ml-4 underline text-primary-700"
						onClick={() => {
							copy(secret);
							setCopied(true);
						}}
					>
						{copied ? 'Copied' : 'Copy'}
					</button>
				</div>
				<div className="space-y-1 md:space-y-4">
					<p className="font-bold">Don&apos;t have an authenticator app?</p>
					<p>
						Don&apos;t worry, you can download free versions from Google and Microsoft.
						Simply search &apos;authenticator&apos; in your app store to find the right
						one for you.
					</p>
					<p>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.apple.com/uk/app-store/"
						>
							Apple App Store
						</a>
						<span className="mx-4">|</span>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://play.google.com/store/search?q=authenticator%20app"
						>
							Google Play Store
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};
