import getConfig from './config';

const qs = new URLSearchParams(window.location.search);

const setSelectedValuesFromQueryString = () => {
	// This was added for cypress running locally
	if (qs.get('selectedEnv')) {
		window.localStorage.setItem('selectedEnv', qs.get('selectedEnv'));
	}
};

const mapping = {
	localhost: 'dev',
	'sandbox-account.brighthr.com': 'sandbox',
	'account.brighthr.com': 'prod',
};

setSelectedValuesFromQueryString();

const environment = mapping[window.location.hostname] || 'sandbox';

export default () => ({
	BUILD_VERSION: process.env.BUILD_SOURCEVERSION,
	environment,
	...getConfig(environment),
});
