import http from 'utils/http';
import config from 'utils/config';

const getEmployee = () => {
	const { ACCOUNT_API } = config();
	const uri = `${ACCOUNT_API}/v1/account`;

	return http.get(uri).then((res) => res.data);
};

export default getEmployee;
