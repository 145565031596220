import React from 'react';

const Layout = ({ children }) => {
	return (
		<main className="flex items-center justify-center px-8 pb-24 mx-auto my-0 mt-12 lg:w-auto">
			{children}
		</main>
	);
};

export default Layout;
