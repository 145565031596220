import React from 'react';
import SSOImage from 'components/SingleSignOnTile/assets/sso-image.svg';
import Button from '@brighthr/component-button';
import getToken from 'utils/getToken';
import ssoSetup from 'services/sso/setup';

const SetupStep = ({ ssoProviders = [], ssoStatus = [], handleUnlink, ga }) => {
	const { given_name: firstName } = getToken();

	if (ssoStatus.some((idpStatus) => idpStatus.isLoading)) return null;

	const status = {
		Google: {
			id: ssoStatus?.[0]?.data?.id,
			email: ssoStatus?.[0]?.data?.email,
			disabled: ssoStatus?.[0]?.data?.usedForSignup,
		},
		Microsoft: {
			id: ssoStatus?.[1]?.data?.id,
			email: ssoStatus?.[1]?.data?.email,
			disabled: ssoStatus?.[1]?.data?.usedForSignup,
		},
		Apple: {
			id: ssoStatus?.[2]?.data?.id,
			email: ssoStatus?.[2]?.data?.email,
			disabled: ssoStatus?.[2]?.data?.usedForSignup,
		},
		Facebook: {
			id: ssoStatus?.[3]?.data?.id,
			email: ssoStatus?.[3]?.data?.email,
			disabled: ssoStatus?.[3]?.data?.usedForSignup,
		},
	};

	return (
		<div className="flex flex-col sm:mb-20 lg:grid lg:grid-cols-12 lg:gap-12 lg:grid-rows-1">
			<div className="lg:col-span-9">
				<h1 className="text-xl font-semibold md:text-2xl">{`Hi ${firstName}, you’re eligible to use single sign-on (SSO) for BrightHR.`}</h1>
				<div className="mb-12">
					<a
						href="/security/what-is-sso"
						onClick={() => ga('What is Single sign-on (SSO)?')}
					>
						What is Single sign-on (SSO)
					</a>
				</div>
				<p className="mb-8 md:mb-12">
					Click one of the options below to link it with your BrightHR account.
				</p>
				{ssoProviders.length &&
					ssoProviders.map((provider) =>
						status?.[provider.name]?.id === undefined ? (
							<div
								className="flex flex-col p-1 mb-4 border rounded shadow xs:flex-row xs:justify-between solid sm:p-4 animate-pulse"
								key={provider.name}
								aria-busy="true"
								aria-live="polite"
								data-testid="sso-loader"
							>
								<div className="flex justify-center py-4 my-2 xs:my-0 xs:justify-start">
									<div className="self-center w-12 h-12 rounded bg-neutral-400" />
									<div className="self-center w-20 h-4 mx-4 rounded-sm bg-neutral-400 xs:w-24" />
								</div>
								<div className="self-center hidden w-48 h-4 rounded bg-neutral-400 sm:block" />
								<div className="self-center w-full h-8 mb-4 rounded bg-neutral-400 xs:w-24 xs:mb-0" />
							</div>
						) : (
							<article
								className="flex flex-col flex-wrap p-1 mb-4 border rounded shadow xs:flex-row xs:justify-between solid sm:p-4"
								key={provider.name}
							>
								<div className="flex items-center content-around justify-center w-full h-16 xs:w-4/12 xs:justify-start">
									<img className="h-8 m-2" src={provider.logo} alt="" />
									<h3 className="self-center xs:text-xl">{provider.name}</h3>
								</div>
								{status?.[provider.name]?.email && (
									<div className="self-center hidden w-5/12 md:flex">
										<p className="hidden truncate text-neutral-700 md:block">
											{status[provider.name].email}
										</p>
									</div>
								)}
								<div className="flex items-center content-center w-full xs:w-auto">
									{status?.[provider.name] && (
										<Button
											disabled={status[provider.name].disabled}
											onClick={() =>
												!status[provider.name].id
													? (() => {
															ga('Link Account', provider.name);
															ssoSetup(provider.name);
													  })()
													: (() => {
															ga('Unlink Account,', provider.name);
															handleUnlink(provider.name);
													  })()
											}
											outline={!!status[provider.name].id}
											text={
												status[provider.name].id
													? 'Unlink Account'
													: 'Link Account'
											}
											color="accent"
										/>
									)}
								</div>
								{status?.[provider.name]?.disabled && (
									<div className="text-right basis-full">
										This option cannot be unlinked as it was used during account
										signup
									</div>
								)}
							</article>
						)
					)}
			</div>
			<div className="hidden lg:col-span-3 lg:flex lg:flex-col lg:grid-rows-1">
				<img src={SSOImage} alt="" />
			</div>
		</div>
	);
};

export default SetupStep;
