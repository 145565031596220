import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from '@brighthr/component-icon';
import InputBox from './InputBox';

const validate = (e, values, setFieldValue) => {
	setFieldValue('validation', {
		validation0: e.currentTarget.value.match(/(?=.*[A-Z])/),
		validation1: e.currentTarget.value.match(/(?=.*[\d\W_])/),
		validation2: e.currentTarget.value.match(/[A-Za-z\d\W_]{12,}/),
		validation3: e.currentTarget.value === values.confirmpassword,
	});
};

const PasswordFields = ({ formik, categoryName }) => {
	const { values } = formik;
	const requirements = ['1 uppercase letter', '1 number/symbol', 'Minimum 12 characters'];
	const [passwordInput, setPasswordInput] = useState('password');

	return (
		<div>
			<p className="mt-8 text-lg font-semibold text-neutral-700">Password rules</p>
			{requirements.map((requirement, index) => {
				const validationIndex = values.validation[`validation${index}`];

				return (
					<div key={requirement} className="flex items-center my-4 text-sm">
						<div className="flex items-center content-center w-5 h-5 mr-3">
							<div
								className={classnames(
									'inline-flex justify-center w-5 h-5 rounded-full',
									{
										'bg-success-600': validationIndex,
										'border-neutral-500 border': !validationIndex,
									}
								)}
								data-testid={`validation${index}`}
							>
								<Icon
									iconName="tick-thick"
									size={30}
									className={classnames('self-center w-3 h-2', {
										'fill-neutral-500': !validationIndex,
										'fill-white': validationIndex,
									})}
									data-testid={`tick${index}`}
								/>
							</div>
						</div>
						{requirement}
					</div>
				);
			})}
			<div>
				<p className="mr-1 text-xs text-right text-neutral-600 md:-mb-2 ">
					New passwords must match
				</p>
				<InputBox
					passwordInput={passwordInput}
					setPasswordInput={setPasswordInput}
					val={values.newpassword}
					label="New password"
					name="newpassword"
					onChangeValidate={(e) => {
						formik.handleChange(e);
						validate(e, values, formik.setFieldValue);
					}}
					actionName="New password"
					categoryName={categoryName}
				/>
				<InputBox
					passwordInput={passwordInput}
					setPasswordInput={setPasswordInput}
					val={values.confirmpassword}
					label="Confirm password"
					name="confirmpassword"
					onChangeValidate={(e) => {
						formik.handleChange(e);
						formik.setFieldValue('validation', {
							...values.validation,
							validation3: e.currentTarget.value === values.newpassword,
						});
					}}
					errorstate={!values.validation.validation3 && values.confirmpassword !== ''}
					actionName="Confirm new password"
					categoryName={categoryName}
				/>
			</div>
		</div>
	);
};

export default PasswordFields;
