import http from 'utils/http';
import config from 'utils/config';

const unlink = (provider) => {
	const { AUTHORISATION_SERVER_URL: authServerAPI } = config();
	const uri = `${authServerAPI}/sso/${provider}/unlink`;

	return http
		.post(uri)
		.then((res) => res.data)
		.catch((err) => err);
};

export default unlink;
