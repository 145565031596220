import React, { useState, useRef } from 'react';
import { logout } from 'hooks/useAuthentication';
import getToken from 'utils/getToken';
import Icon from '@brighthr/component-icon';
import onClickOutside from 'utils/onClickOutside';
import AppSwitcher from 'layouts/AccountHeader/AppSwitcher';
import Avatar from '@brighthr/component-avatar';

export default () => {
	const [showDropDown, setShowDropDown] = useState(false);
	const {
		picture: avatar,
		family_name: surname,
		given_name: firstName,
		job_title: jobTitle,
	} = getToken();
	const name = `${firstName} ${surname}`;

	const ref = useRef();
	onClickOutside(ref, () => setShowDropDown(false));

	return (
		<div className="relative" ref={ref}>
			<button
				type="button"
				className="p-2 pb-0 border-l cursor-pointer border-primary-100 hover:bg-primary-100 focus:outline-none"
				onClick={() => {
					setShowDropDown(!showDropDown);
				}}
			>
				<Avatar imageHref={avatar} name={name} size="base" />
			</button>
			{showDropDown && (
				<div
					className="absolute right-0 z-50 border border-primary-100 "
					style={{ width: '300px' }}
				>
					<div className="flex items-center p-4 bg-primary-50">
						<Avatar name={name} size="lg" />
						<div className="px-4">
							<div>{name}</div>
							<div className="text-sm">{jobTitle}</div>
						</div>
					</div>
					<div className="p-4 bg-white">
						<AppSwitcher />
						<hr className="w-full my-3" />
						<button
							type="button"
							onClick={() => logout()}
							className="flex items-center w-full mt-3 text-left text-primary-700 focus:outline-none"
						>
							<Icon iconName="power" size={30} className="mr-3 fill-primary-400" />{' '}
							Logout
						</button>
					</div>
				</div>
			)}
		</div>
	);
};
