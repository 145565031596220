import React from 'react';
import { logout } from 'hooks/useAuthentication';
import Button from '@brighthr/component-button';

import Layout from 'layouts';

export default ({ heading, subheading }) => (
	<Layout>
		<div className="flex flex-col items-center text-center">
			<h1 className="m-0 text-6xl text-primary-200 sm:text-9xl">Sorry</h1>
			<h2 className="mb-4 text-2xl text-neutral-700">{heading}</h2>
			<p className="mb-4">{subheading}</p>
			<Button
				onClick={() => {
					logout();
				}}
				text="Logout"
			/>
		</div>
	</Layout>
);
