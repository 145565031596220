import React from 'react';
import { Toaster } from '@brighthr/component-toast';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import config from 'utils/config';
import { QueryClient, QueryClientProvider } from 'react-query';
import NotFound from 'pages/Errors/NotFound';
import initializeTrackJs from 'utils/initializeTrackJs';
import ErrorBoundary from 'utils/errorBoundary';
import { initialize, pageview } from './utils/GA';
import AuthenticatedRoutes from './routing/Authenticated';
import PublicRoutes from './routing/Public';
import EnvSwitch from './components/EnvSwitch';

import useAuthentication from './hooks/useAuthentication';

const { GA_TRACKING_ID, MEASUREMENT_ID, environment } = config();

initialize({
	trackingId: GA_TRACKING_ID,
	measurementId: MEASUREMENT_ID,
});
pageview(window.location.pathname + window.location.search);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

initializeTrackJs();
function App() {
	const { isAuthenticated } = useAuthentication();
	return (
		<ErrorBoundary>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<Switch>
						{isAuthenticated && AuthenticatedRoutes}
						{PublicRoutes}
						{isAuthenticated && <Route path="*" component={NotFound} />}
					</Switch>
				</BrowserRouter>
				{(environment === 'dev' || environment === 'sandbox') && <EnvSwitch />}
				<Toaster />
			</QueryClientProvider>
		</ErrorBoundary>
	);
}

export default App;
