import React, { useState, useRef } from 'react';
import Button from '@brighthr/component-button';
import config from 'utils/config';
import onClickOutside from 'utils/onClickOutside';
import { event } from 'utils/GA';
import localise from 'utils/localise';
import Icon from '@brighthr/component-icon';
import Android from './assets/GoogleLogo';
import Ios from './assets/IosLogo';
import getToken from '../../../utils/getToken';

const gaCategory = 'App switcher';

const makeList = ({ title, subtitle, link, icon, iosMarket, androidMarket, hidden }) => {
	if (hidden) return null;
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	const isAndroid = /android/i.test(userAgent);
	const isIos = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

	return (
		<li
			className="flex items-center justify-between p-4 border-b border-neutral-400 last:border-0"
			key={title}
		>
			<div className="flex items-center space-x-6">
				<Icon iconName={icon} size={40} className="fill-accent-500" />

				<div className="sm:w-64">
					<a
						href={link}
						target={
							['Blip', 'Pop', 'BrightAdvice'].includes(title) ? '_blank' : '_self'
						}
						rel="noopener noreferrer"
						onClick={() =>
							event({
								category: gaCategory,
								action: 'Link clicked',
								label: title,
							})
						}
					>
						{title}
					</a>
					<div className="text-xs">{subtitle}</div>
				</div>
			</div>
			<div className="flex space-x-8">
				{isIos && (
					<a
						data-testid="isIos"
						href={iosMarket}
						onClick={() =>
							event({
								category: gaCategory,
								action: 'iOS link clicked',
								label: title,
							})
						}
					>
						<Ios />
					</a>
				)}
				{isAndroid && (
					<a
						data-testid="isAndroid"
						href={androidMarket}
						onClick={() =>
							event({
								category: gaCategory,
								action: 'Android link clicked',
								label: title,
							})
						}
					>
						<Android />
					</a>
				)}
			</div>
		</li>
	);
};

const AppSwitcher = () => {
	const [isOpen, setIsOpen] = useState(false);

	const { HR_URL, BRIGHTSAFE_URL } = config();
	const {
		BLIP_DOTCOM_LINK,
		POP_DOTCOM_LINK,
		BRIGHTADVICE_DOTCOM_LINK,
		BRIGHTADVICE_SUBHEADING,
	} = localise();

	const isNewZealand = () => getToken().locale === 'en-nz';

	const list = [
		{
			title: 'BrightHR',
			subtitle: 'Core HR services',
			link: HR_URL,
			icon: 'xing',
			iosMarket: 'itms-apps://itunes.apple.com/app/brighthr/id1031619349',
			androidMarket: 'market://details?id=brighthr.com.brighthr',
		},
		{
			title: 'BrightSafe',
			subtitle: 'Health & safety support',
			link: BRIGHTSAFE_URL,
			icon: 'shield',
			iosMarket: 'itms-apps://itunes.apple.com/app/brightsafe-on-the-go/id1543203234',
			androidMarket: 'market://details?id=com.bright.brightsafe_mobile',
		},
	];
	const mobileList = [
		{
			title: 'Blip',
			subtitle: 'Clocking in system',
			link: BLIP_DOTCOM_LINK,
			icon: 'radar',
			iosMarket: 'itms-apps://itunes.apple.com/app/blip/id1294710168',
			androidMarket: 'market://details?id=brighthr.blip',
		},
		{
			title: 'Pop',
			subtitle: 'Expense and mileage tracker',
			link: POP_DOTCOM_LINK,
			icon: 'receipt',
			iosMarket: 'itms-apps://itunes.apple.com/app/pop-by-brighthr/id1434381934',
			androidMarket: 'market://details?id=com.paperwork.brighthr',
		},
		{
			title: 'BrightAdvice',
			subtitle: BRIGHTADVICE_SUBHEADING,
			link: BRIGHTADVICE_DOTCOM_LINK,
			icon: 'message-mobile',
			iosMarket: 'itms-apps://itunes.apple.com/app/brightadvice/id1444886148',
			androidMarket: 'market://details?id=com.brighthr.brightadvice',
			hidden: isNewZealand(),
		},
	];

	const ref = useRef();
	onClickOutside(ref, () => setIsOpen(false));

	return (
		<div className="z-10 sm:relative top-1">
			<Button
				onClick={() => {
					setIsOpen(!isOpen);

					event({
						category: gaCategory,
						action: 'Opened',
					});
				}}
				textButton
				color="primary"
				text="Bright Apps"
				iconName="menu-apps-2x2"
				size="lg"
				iconPosition="left"
			/>
			{isOpen && (
				<div
					className="absolute left-0 right-0 p-8 mt-4 bg-white border rounded-md shadow-lg border-neutral-200 sm:left-auto"
					ref={ref}
				>
					<ul className="space-y-4">
						<li>
							<b>Bright Products</b>
							<ul>{list.map((listItems) => makeList(listItems))}</ul>
						</li>
						<li>
							<b>Only available on the App Stores</b>
							<ul>{mobileList.map((listItems) => makeList(listItems))}</ul>
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default AppSwitcher;
