import { useState } from 'react';
import { useQueries } from 'react-query';
import ssoStatus from '..';

export default (userId, reactQueryOptions = {}) => {
	const [settled, setSettled] = useState({ google: false, ms: false, facebook: false });

	return useQueries([
		{
			queryKey: ['googleStatus', userId],
			queryFn: () => ssoStatus('google'),
			...reactQueryOptions,
			enabled: !settled.google,
			onSettled: ({ id, email }) =>
				id !== undefined && email !== undefined && setSettled({ ...settled, google: true }),
		},
		{
			queryKey: ['microsoftStatus', userId],
			queryFn: () => ssoStatus('microsoft'),
			...reactQueryOptions,
			enabled: !settled.ms,
			onSettled: ({ id, email }) =>
				id !== undefined && email !== undefined && setSettled({ ...settled, ms: true }),
		},
		{
			queryKey: ['appleStatus', userId],
			queryFn: () => ssoStatus('apple'),
			...reactQueryOptions,
			enabled: !settled.ms,
			onSettled: ({ id, email }) =>
				id !== undefined && email !== undefined && setSettled({ ...settled, ms: true }),
		},
		{
			queryKey: ['facebookStatus', userId],
			queryFn: () => ssoStatus('facebook'),
			...reactQueryOptions,
			enabled: !settled.facebook,
			onSettled: ({ id, email }) =>
				id !== undefined &&
				email !== undefined &&
				setSettled({ ...settled, facebook: true }),
		},
	]);
};
