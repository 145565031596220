import { object, string } from 'yup';

// used to filter out invalid characters from the 'local' of an email address
// local@domain.x
const containsInvalidLocalCharacters = (str) => /[@|\s]/.test(str);

const schema = object({
	email: string()
		.required()
		.label('Email address')
		.test((v) => !containsInvalidLocalCharacters(v)),
	domain: string().required().label('Domain'),
});

export default schema;
