import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../logo';
import ChevronRight from './assets/chevron-right.svg';

const Layout = ({ children }) => {
	return (
		<>
			<header className="p-2 mb-8 border-b-2 bg-primary-50 border-primary-100">
				<div className="flex flex-col justify-between max-w-screen-lg mx-auto md:p-8 sm:flex-row">
					<div>
						<Link to="/" aria-label="Back to home">
							<Logo
								className="w-48 mx-auto my-0 fill-current md:m-0 text-primary-400"
								alt="Bright logo"
							/>
						</Link>
						<div className="flex justify-between mt-8 text-lg">
							<Link to="/" className="mx-auto font-semibold md:no-underline sm:mx-0">
								My Account
							</Link>
							<img src={ChevronRight} alt="" className="hidden px-4 md:block" />
							<p className="hidden md:block">Single sign-on (SSO) setup</p>
						</div>
					</div>
					<div className="mt-4 text-center sm:text-right">
						<h2 className="text-neutral-700">Single sign-on (SSO) setup</h2>
					</div>
				</div>
			</header>
			<main className="max-w-screen-lg p-2 pb-24 mx-auto my-0 md:p-4">{children}</main>
		</>
	);
};

export default Layout;
