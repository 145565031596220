/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import Button from '@brighthr/component-button';

const MobileView = ({ setShowDeleteDelegateModal, delegateInfo, sortedDelegateData }) => {
	return (
		<>
			{sortedDelegateData.map((delegateData) => (
				<div className="block m-4 overflow-hidden border rounded-md border-neutral-200 md:hidden">
					<div className="px-6 py-4 text-base text-neutral-700">
						<div className="font-bold">Delegate name</div>
						<p className="mb-4">{delegateData.name}</p>
						<div className="font-bold">Email</div>
						<p className="mb-4">{delegateData.email}</p>
						<div className="font-bold">Added by</div>
						<p className="mb-4">{delegateData.addedBy}</p>
						<Button
							outlineButton
							fullWidth
							text="Remove delegate"
							onClick={() => {
								setShowDeleteDelegateModal(true);
								delegateInfo(delegateData.name, delegateData._links.item.href);
							}}
						/>
					</div>
				</div>
			))}
		</>
	);
};

export default MobileView;
