import http from 'utils/http';
import config from 'utils/config';

const fullSignup = (data) => {
	const { AUTHORISATION_SERVER_URL } = config();
	const uri = `${AUTHORISATION_SERVER_URL}/service/account`;

	return http.post(uri, { data, withCredentials: true }).then((res) => res.data);
};

export default fullSignup;
