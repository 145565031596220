import http from 'utils/http';
import config from 'utils/config';

const checkResetPasswordGuid = (guid) => {
	const { ACCOUNT_API } = config();
	const uri = `${ACCOUNT_API}/v1/account/password-reset/${guid}`;

	return http.get(uri).then((res) => res.data);
};

export default checkResetPasswordGuid;
