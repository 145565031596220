import http from 'utils/http';
import config from 'utils/config';

const allEmployees = () => {
	const { GLOBAL_API } = config();
	const uri = `${GLOBAL_API}employee`;

	return http.get(uri).then((res) => res.data);
};

export default allEmployees;
