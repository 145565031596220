import React from 'react';
import classnames from 'classnames';
import Input from '@brighthr/component-input';

const InputBox = ({
	val,
	label,
	name,
	onChangeValidate,
	errorstate: hasError = false,
	setPasswordInput,
	passwordInput,
	actionName,
	categoryName,
	...rest
}) => {
	const showPassword = () => {
		if (passwordInput === 'password') {
			setPasswordInput('text');
		} else {
			setPasswordInput('password');
		}
	};

	return (
		<div>
			<label className={classnames('')} val={val} htmlFor={name}>
				{label}
			</label>
			<div className="">
				<Input
					value={val}
					autoComplete="new-password"
					name={name}
					type={passwordInput}
					data-testid={name}
					onChange={onChangeValidate}
					maxLength="250"
					id={name}
					{...rest}
					rightIconName={passwordInput === 'password' ? 'visibility' : 'visibility-none'}
					buttonAriaLabel="visibility"
					onClickButton={() => {
						showPassword();
					}}
				/>
			</div>
			{hasError && (
				<div className="w-64 text-xs lg:w-auto text-error-800">
					Sorry, the passwords you have entered do not match, please try again
				</div>
			)}
		</div>
	);
};

export default InputBox;
