import React from 'react';

export default () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#475966">
		<defs />
		<path fill="none" d="M0 0h24v24H0z" />
		<path
			className="b"
			d="M18.038 12.667a5.012 5.012 0 012.395-4.208 5.151 5.151 0 00-4.056-2.187c-1.706-.178-3.36 1.019-4.229 1.019-.887 0-2.225-1-3.667-.971a5.4 5.4 0 00-4.548 2.76c-1.965 3.394-.5 8.382 1.383 11.126.942 1.343 2.042 2.843 3.483 2.79 1.409-.059 1.936-.9 3.636-.9 1.686 0 2.179.9 3.648.864 1.513-.025 2.465-1.35 3.374-2.706a11.092 11.092 0 001.542-3.134 4.849 4.849 0 01-2.961-4.453zM15.253 4.491A4.87 4.87 0 0016.368 1a4.962 4.962 0 00-3.208 1.66 4.637 4.637 0 00-1.143 3.361 4.1 4.1 0 003.236-1.53z"
		/>
	</svg>
);
