import { useEffect } from 'react';

const onClickOutside = (ref, handler) => {
	useEffect(() => {
		const handleClick = (e) => {
			if (
				ref.current &&
				!ref.current.contains(e.target) &&
				!e.target.closest('.ignoreClickOutside')
			) {
				handler();
			}
		};
		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, [handler, ref]);
};

export default onClickOutside;
