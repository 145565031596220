import http from 'utils/http';
import config from 'utils/config';

const verify = (code) => {
	const { ACCOUNT_API } = config();
	const uri = `${ACCOUNT_API}/v1/account/authenticator`;

	return http
		.post(uri, {
			data: {
				code,
			},
		})
		.then((res) => res.data);
};

export default verify;
