import getToken from 'utils/getToken';

function getConfigOverrides() {
	return JSON.parse(window.sessionStorage.getItem('config') || '{}');
}

const configBase = (env = 'prod') => {
	const { brighthr_app_region: country = 'UK' } = getToken();
	const getEnv = (config) => {
		const firstLevel = config[country.toLowerCase()] || config[env] || config.default;

		return firstLevel[env] || firstLevel.default || firstLevel;
	};

	return {
		GLOBAL_API: getEnv({
			default: 'https://sandbox-api.brighthr.com/v1/',
			prod: 'https://api.brighthr.com/v1/',
		}),

		ACCOUNT_API: getEnv({
			default: 'https://sandbox-api.brighthr.com',
			prod: 'https://api.brighthr.com',
		}),

		REG_URL: getEnv({
			default: 'https://sandbox-api.brighthr.com',
			prod: 'https://api.brighthr.com',
		}),

		REG_CODE: getEnv({
			default: 'WmxkerlnnMVXUq6zvvq6bFEbJYlSB0bHwgPYBSDEleo/7eM2N0Jr/w==',
			prod: 'nW7FfEpQEbWOlZ71ybdm1vJTpc/HaJ8pBErcbYDj9qGPm7JlVVVVTw==',
		}),

		AUTHORISATION_SERVER_URL: getEnv({
			default: 'https://sandbox-login.brighthr.com',
			prod: 'https://login.brighthr.com',
		}),

		GA_TRACKING_ID: getEnv({
			default: '',
			prod: 'UA-67739736-13',
		}),

		MEASUREMENT_ID: getEnv({
			default: 'G-JHRQ2YJJ27',
			prod: 'G-0NPJV2YJ85',
		}),

		HR_URL: getEnv({
			uk: {
				default: 'https://sandbox-app.brighthr.com',
				prod: 'https://app.brighthr.com',
			},
			ca: {
				default: 'https://sandbox-app.brighthr.ca',
				prod: 'https://app.brighthr.ca',
			},
			ie: {
				default: 'https://sandbox-app.brighthr.ie',
				prod: 'https://app.brighthr.ie',
			},
			au: {
				default: 'https://sandbox-app.brighthr.com.au',
				prod: 'https://app.brighthr.com.au',
			},
			qa01: 'https://qa01-app.brighthr.com',
			qa02: 'https://qa02-app.brighthr.com',
			qa03: 'https://qa03-app.brighthr.com',
			qa04: 'https://qa04-app.brighthr.com',
			qa05: 'https://qa05-app.brighthr.com',
			qa06: 'https://qa06-app.brighthr.com',
			qa07: 'https://qa07-app.brighthr.com',
		}),

		BRIGHTSAFE_URL: getEnv({
			prod: 'https://app.brightsafe.com',
			default: 'https://app-uat.brightsafe.com',
		}),

		VACCTRAK_URL: getEnv({
			default: 'https://sandbox-www.vacctrak.com/vacctrak',
			dev: 'http://localhost:9004/vacctrak',
			prod: 'https://www.vacctrak.com/vacctrak',
		}),

		LIGHTNING_URL: getEnv({
			default: 'https://sandbox-brainbox.brighthr.com/login',
			dev: 'http://localhost:3000/login',
			prod: 'https://brainbox.brighthr.com/login',
		}),

		EXCHANGE_URL: getEnv({
			default: 'https://sandbox-exchange.brighthr.com/marketplace',
			prod: 'https://exchange.brighthr.com/marketplace',
		}),

		SITE_KEY: getEnv({
			default: '0x4AAAAAAABUwHGgchMHC63h',
			prod: '0x4AAAAAAABliJ978LUZJMk5',
		}),

		TERMS_LINK: getEnv({
			uk: 'https://www.brighthr.com/terms',
			ca: 'https://www.brighthr.com/ca/terms',
			au: 'https://www.brighthr.com/au/terms',
			ie: 'https://www.brighthr.com/ie/terms',
		}),
	};
};

export const set = (configOverrides) => {
	window.sessionStorage.setItem('config', JSON.stringify(configOverrides));
	window.location.reload();
};

export const clear = () => {
	window.sessionStorage.removeItem('config');
	window.location.reload();
};

export default (environment) => {
	return {
		...configBase(environment),
		...getConfigOverrides(),
	};
};
