import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../logo';

const Layout = ({ children, step }) => {
	return (
		<>
			<header className="p-2 mb-8 border-b-2 bg-primary-50 border-primary-100">
				<div className="flex flex-col justify-between max-w-screen-lg mx-auto md:p-8 sm:flex-row">
					<Link to="/" aria-label="Back to home">
						<Logo
							className="w-48 mx-auto my-0 fill-current md:m-0 text-primary-400"
							role="img"
						/>
					</Link>
					<div className="mt-4 text-center sm:text-right">
						<h2 className="text-neutral-700">Two-Factor authentication (2FA) setup</h2>
						{step > 0 && `step ${step} of 3`}
					</div>
				</div>
			</header>
			<main className="max-w-screen-lg p-2 pb-24 mx-auto my-0 md:px-4">{children}</main>
		</>
	);
};

export default Layout;
