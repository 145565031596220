import http from 'utils/http';
import config from 'utils/config';

const registration = ({ data }) => {
	const { REG_URL } = config();
	const url = `${REG_URL}/v1/registration`;

	return http.post(url, { data });
};

export default registration;
