import React from 'react';
import { Route } from 'react-router-dom';

import ClaimInvite from '../../pages/claimInvite';
import RestrictAccess from '../../pages/restrictAccess';
import RestrictCountryAccess from '../../pages/restrictCountryAccess';
import Signup from '../../pages/signup';
import CreatePassword from '../../pages/password/createPassword';
import ResetPassword from '../../pages/password/resetPassword';

const AuthPlaceholderRoute = () => null;

const Routes = [
	<Route path="/signup" exact component={Signup} />,
	<Route path="/signup/:guid" component={CreatePassword} />,
	<Route path="/invite/:guid/:variant?" component={ClaimInvite} />,

	<Route path="/package-client-error" component={RestrictAccess} />,
	<Route path="/country-client-error" component={RestrictCountryAccess} />,
	<Route path="/reset-password/:guid" component={ResetPassword} />,

	// Needed due to auth hook - return null
	<Route exact path="/logout" component={AuthPlaceholderRoute} />,
	<Route path="/login/callback" component={AuthPlaceholderRoute} />,
];

export default Routes;
