import React from 'react';
import localise from 'utils/localise';
import SorryLayout from '../SorryLayout';

export default () => {
	const { SUPPORT_PHONE, SUPPORT_EMAIL } = localise();
	return (
		<SorryLayout
			heading="You no longer have access to your Bright account"
			subheading={
				<>
					We&apos;d like to get this sorted for you right away, so please give us a call
					on <a href={`tel:${SUPPORT_PHONE}`}>{SUPPORT_PHONE}</a> or drop us a message at{' '}
					<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> and we&apos;ll get you
					back up and running.
				</>
			}
		/>
	);
};
