import React from 'react';
import classnames from 'classnames';
import laptopAndPhone from '../assets/laptopAndPhone.svg';
import appStoresMobile from '../assets/appStoresMobile.svg';
import QRCodeScan from '../assets/QRCodeScan.svg';
import codesExample from '../assets/codesExample.svg';

const steps = [
	{
		title: 'Turn on Two-Factor authentication',
		body: `By default, Two-Factor authentication is not active unless you, the user,
				turns it on. Once turned on you’ll be invited to download a third party
				<b>authenticator app</b> from your mobile phone app store.`,
		image: laptopAndPhone,
		imageAlt: 'Laptop and phone',
		imageRight: true,
	},
	{
		title: 'Download an authenticator app',
		body: `<p>
					An <b>authentication app</b> works by using a mobile app to generate an
					<b>6-digit authentication code</b>.
				</p>
				<p>Don’t have an authenticator app? Download one from:</p>
				<p>
					<a href="https://www.apple.com/uk/app-store/">Apple App Store</a> or
					<a href="https://play.google.com/store/search?q=authenticator%20app">Google Play Store</a>
				</p>`,
		image: appStoresMobile,
		imageAlt: 'App store mobile',
	},
	{
		title: 'Scan your QR code',
		body: 'Scan your QR code with your authenticator app. This will generate a 6-digit code.',
		image: QRCodeScan,
		imageAlt: 'QR code scan',
		imageRight: true,
	},
	{
		title: 'Don’t forget your recovery codes!',
		body: `<p>
					What happens if you enable Two-Factor authentication but you don’t have
					access to your phone the next time you attempt to login? This is where
					you recovery codes come in.
				</p>
				<p>
					Download, print and <b>store your recovery codes in a safe place</b>.
				</p>`,
		image: codesExample,
		imageAlt: 'codes example',
		showWarning: true,
	},
];

const Warning = () => (
	<div className="p-4 mt-8 text-sm bg-error-100">
		<b>Remember:</b> These recovery codes are your way in just in case you lose your phone – so
		don’t store them on your phone!
	</div>
);

export default () => {
	return (
		<div className="mt-4 mb-56">
			<div className="mb-20">
				<h1>How Two-Factor authentication works</h1>
				Two-Factor authentication – sometimes called “2FA” – works by adding an{' '}
				<b>additional layer of security</b> to your online accounts. It requires an
				additional login credential – beyond just the username and password – to gain
				account access, and getting that second credential requires access to something that
				belongs to you.
			</div>

			{steps.map(({ title, body, image, imageAlt, imageRight, showWarning }, index) => {
				return (
					<div
						className={classnames({
							'flex items-center justify-between mb-16': true,
							'md:flex-row-reverse': imageRight,
						})}
						key={title}
					>
						<img src={image} alt={imageAlt} className="hidden md:block" />
						<div className="md:w-1/2">
							<h2 className="text-neutral-700">{`Step ${index + 1}`}</h2>
							<h2 className="text-neutral-700 ">{title}</h2>
							{/* eslint-disable-next-line react/no-danger */}
							<div className="space-y-4" dangerouslySetInnerHTML={{ __html: body }} />
							{showWarning && <Warning />}
						</div>
					</div>
				);
			})}

			<div>
				<h3 className="text-xl font-bold">Next time you login…</h3>
				Once Two-Factor authentication is enabled on your account you’ll benefit from
				enhanced security. This means you’ll need your authenticator app to login again.
				Failing that, you’ll need your recovery codes.
			</div>
		</div>
	);
};
