import React from 'react';
import getToken from 'utils/getToken';
import { useHistory } from 'react-router-dom';

import BrightLogo from '../assets/bright-logo.svg';
import LinkIcon from '../assets/link.svg';

const ConfirmStep = ({ providerLinked, handleUnlink, ga }) => {
	const { email: brightEmail } = getToken();
	const history = useHistory();

	const handleCancelLink = () => {
		history.push('/sso');
		handleUnlink(providerLinked?.name);
	};

	return (
		<div className="sm:mb-20">
			<h1 className="mb-10 text-xl font-semibold md:text-2xl">
				Success, single sign-on has now been enabled! We&apos;ll send a confirmation to the
				email address you&apos;ve got registered on BrightHR
			</h1>
			<div className="flex flex-col mb-4 sm:grid sm:grid-cols-11">
				<div className="col-span-5 mb-8 sm:mb-0">
					<div className="flex flex-col h-24 p-4 mb-2 border rounded shadow">
						<div className="flex items-center content-center mb-2">
							<img className="h-8 mx-2" src={providerLinked?.logo} alt="" />
							<h2 className="self-center mb-0 text-2xl font-normal text-neutral-700">
								{providerLinked?.name}
							</h2>
						</div>
						<p className="ml-2 text-neutral-700">{providerLinked?.email}</p>
					</div>
					<button
						className="text-[#3366CC] underline hover:text-[#3366CC] sm:mb-10"
						type="button"
						onClick={() => {
							ga("This account shouldn't be linked");
							handleCancelLink();
						}}
					>
						Unlink this account
					</button>
				</div>
				<div className="self-center h-auto col-span-1 mb-8 sm:h-24 justify-self-center sm:mb-0">
					<img src={LinkIcon} alt="" />
				</div>
				<div className="flex flex-col h-24 col-span-5 p-4 mb-10 border rounded shadow sm:mb-0">
					<div className="flex items-center content-center mb-2">
						<img className="h-8 mx-2" src={BrightLogo} alt="" />
						<h2 className="self-center mb-0 text-2xl font-normal text-neutral-700">
							BrightHR
						</h2>
					</div>
					<p className="ml-2 text-neutral-700">{brightEmail}</p>
				</div>
			</div>
			<p className="mb-4">
				You&#39;re all set up! From now on you can use your {providerLinked?.name}{' '}
				credentials to log in to your Bright products. If you ever lose access to your{' '}
				{providerLinked?.name} account, you can still log in using your original BrightHR
				email address and password.
			</p>
		</div>
	);
};

export default ConfirmStep;
