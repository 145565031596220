import React from 'react';
import SecurityPage from './SecurityPage';
import AboutMe from './AboutMe';
import Layout from '../../layouts/AccountHeader';

const Account = () => (
	<Layout>
		<AboutMe />
		<SecurityPage />
	</Layout>
);

export default Account;
