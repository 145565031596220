import React from 'react';
import { useQuery } from 'react-query';
import Spinner from '@brighthr/component-spinner';
import getToken from 'utils/getToken';
import LoadingContainer from 'utils/loadingContainer';
import getLocation from 'services/location';
import getInfo from 'services/getInfo';
import Avatar from '@brighthr/component-avatar';

export default () => {
	const { picture: avatar, sub: guid } = getToken();

	const { data: location, isLoading: locationLoading } = useQuery(['getLocation', guid], () =>
		getLocation(guid)
	);
	const { data: info, isLoading: infoLoading } = useQuery(['getInfo', guid], () => getInfo());

	const name = `${info?.firstName} ${info?.lastName}`;

	return (
		<>
			<LoadingContainer loading={infoLoading} title="loading-spinner">
				<h2 className="my-8 text-neutral-700">About me</h2>
				<div className="flex flex-col justify-between mb-10 border border-primary-100 rounded-2xl md:flex-row md:rounded-2xl">
					<div className="flex items-center p-4">
						<Avatar imageHref={avatar} name={name} size="xxl" />
						<div className="px-4">
							<div className="text-xl">{name}</div>
							<div className="mt-3 text-md">{info?.jobTitle}</div>
						</div>
					</div>
					<ol className="p-4 sm:pr-32 bg-primary-50 rounded-b-2xl md:rounded-bl-none md:rounded-r-2xl">
						<li className="flex font-medium text-md">Works at</li>
						{locationLoading ? (
							<Spinner
								ariaLabel="loading content"
								className="mr-2 stroke-accent-500"
								size="xs"
							/>
						) : (
							<ul className="flex-row mt-1 text-xs">
								{location[0]?.name || 'No fixed location'}
							</ul>
						)}
						<li className="mt-5 font-medium text-md">Email Address</li>
						<ul className="flex-row mt-1 text-xs">{info?.email}</ul>
					</ol>
				</div>
			</LoadingContainer>
		</>
	);
};
