import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { Modal, ModalBody } from '@brighthr/component-modal';
import Button from '@brighthr/component-button';
import LinkButton from '@brighthr/component-linkbutton';

import * as Yup from 'yup';
import verifyEmployeeRegistration from 'services/verifyEmployeeRegistration';
import config from 'utils/config';
import claimInvite from 'services/claimInvite';
import Icon from '@brighthr/component-icon';
import Spinner from '@brighthr/component-spinner';
import DefaultLayout from '../../layouts/Default';
import HRLiteLayout from '../../layouts/HRLite';
import SafeLiteLayout from '../../layouts/SafeLite';

const schema = (isSignIn = true) => {
	return Yup.object().shape({
		password: Yup.string().label('Password').max(50).required(),
		confirmpassword: isSignIn
			? null
			: Yup.string().label('Confirm password').max(50).required(),
	});
};

const requirements = [
	{
		label: '1 uppercase letter',
		check: (values) => values.password?.match(/(?=.*[A-Z])/) !== null,
	},

	{
		label: 'Minimum 12 characters',
		check: (values) => values.password?.match(/[A-Za-z\d\W_]{12,}/) != null,
	},
	{
		label: '1 number/symbol',
		check: (values) => values.password?.match(/(?=.*[\d\W_])/) !== null,
	},
	{
		label: 'Passwords match',
		check: (values) =>
			!!values.password &&
			!!values.confirmpassword &&
			values.password === values.confirmpassword,
	},
];
const isDisabled = (values) => requirements.some(({ check }) => !check(values));

const { HR_URL, BRIGHTSAFE_URL } = config();

const products = {
	brightsafe: { Name: 'BrightSafe Lite', GetStartedUrl: BRIGHTSAFE_URL, Layout: SafeLiteLayout }, // compatibility with already issued invites
	default: { Name: 'BrightHR', GetStartedUrl: HR_URL, Layout: DefaultLayout },
	'hr-lite': { Name: 'BrightHR Lite', GetStartedUrl: HR_URL, Layout: HRLiteLayout },
	'safe-lite': {
		Name: 'BrightSafe Lite',
		GetStartedUrl: BRIGHTSAFE_URL,
		Layout: SafeLiteLayout,
	},
};

export default ({
	match: {
		params: { guid, variant },
	},
}) => {
	const [submitting, setSubmitting] = useState(false);
	const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
	const [registrationDetails, setRegistrationDetails] = useState({});

	const [registrationStatus, setRegistrationStatus] = useState('UNVERIFIED');
	const [error, setError] = useState(false);

	const { search } = useLocation();
	const URLParams = new URLSearchParams(search);

	const product = products[URLParams.get('product') || variant] || products.default;

	useEffect(() => {
		verifyEmployeeRegistration(guid)
			.then((res) => {
				setRegistrationStatus('VALID');
				setRegistrationDetails(res);
			})
			.catch(() => setRegistrationStatus('NOT_VALID'));
	}, [guid]);

	if (registrationStatus === 'UNVERIFIED') return null;

	const isSignIn = !!(
		registrationDetails?.inviteMode && registrationDetails?.inviteMode === 'SignIn'
	);

	return (
		<product.Layout>
			<div className="max-w-screen-lg">
				{registrationStatus === 'NOT_VALID' && <div>Not found</div>}
				{(submitting || submissionSuccessful) && (
					<Modal width="sm">
						<ModalBody
							className="m-10 text-center"
							data-testid="Freemium submission modal"
						>
							{!submissionSuccessful && (
								<>
									<h1 className="mb-4 text-2xl font-bold text-primary-700">
										You&apos;re almost there…
									</h1>
									<p className="max-w-sm m-auto mb-6">
										Your brand-new {product.Name} account will be ready for you
										in a few moments.
									</p>
									<Spinner
										size="base"
										ariaLabel="loading content"
										className="mx-auto stroke-primary-700"
									/>
								</>
							)}
							{submissionSuccessful && (
								<>
									<h1 className="mb-4 text-2xl font-bold text-primary-700">
										You&apos;re all done!
									</h1>
									<p className="mb-6">
										Your account has now been created. All that&apos;s left for
										you to do is click the button below to start using{' '}
										{product.Name}
									</p>
									<div className="flex justify-center">
										<LinkButton
											text="Get started"
											href={product.GetStartedUrl}
										/>
									</div>
								</>
							)}
						</ModalBody>
					</Modal>
				)}
				{registrationStatus === 'VALID' && (
					<>
						<h1 className="mb-12 text-2xl font-bold text-primary-700">
							Finish setting up your account
						</h1>
						<h2 className="mb-2 text-lg font-bold text-neutral-700">
							Hi, {registrationDetails.firstName}
						</h2>
						<p className="mb-5">{registrationDetails.email}</p>
						<Formik
							initialValues={{
								countryCode: 'GB',
								jurisdictionCode: 'ENGLANDANDWALES',
								password: '',
								confirmpassword: '',
								registrationId: guid,
							}}
							validateOnBlur
							validateOnChange={false}
							validationSchema={schema(isSignIn)}
							onSubmit={({ password, registrationId }) => {
								setSubmitting(true);
								setError(false);
								const payload = { password };
								return claimInvite(payload, registrationId)
									.then(() => {
										setSubmissionSuccessful(true);
										setSubmitting(false);
									})
									.catch((e) => {
										const isError401 = e?.response?.status === 401;
										setError(
											isError401
												? 'Invalid credentials'
												: 'Problem submitting form'
										);
										setSubmitting(false);
									});
							}}
						>
							{(formik) => {
								return (
									<form onSubmit={formik.handleSubmit} className="space-y-6">
										<div>
											<div className="mb-4">
												<label htmlFor="password">
													<div className="">Password</div>
													<Field
														id="password"
														type="password"
														autoComplete="off"
														name="password"
														className="w-full px-3 py-2 m-0 mt-2 border-2 rounded border-neutral-500 "
													/>
												</label>
												{formik.touched.password && (
													<div className="mt-2 text-error-500">
														{formik.errors.password}
													</div>
												)}
											</div>
											{!isSignIn && (
												<ul className="grid grid-cols-2 gap-4">
													{requirements.map((req) => {
														const valid = req.check(formik.values);

														return (
															<li
																className="flex items-center"
																key={req.label}
															>
																<div
																	data-testid="validation"
																	className="flex-none w-4 h-4 mr-3"
																>
																	{valid ? (
																		<Icon
																			iconName="tick-thick"
																			size={20}
																			className="fill-success-600"
																			data-testid="Tick icon"
																		/>
																	) : (
																		<Icon
																			iconName="cross-thick"
																			size={20}
																			className="fill-error-500"
																			data-testid="Cross icon"
																		/>
																	)}
																</div>

																<div className="flex-1">
																	{req.label}
																</div>
															</li>
														);
													})}
												</ul>
											)}
										</div>
										{!isSignIn && (
											<div>
												<label htmlFor="confirmpassword">
													<div className="">Confirm password</div>
													<Field
														id="confirmpassword"
														type="password"
														autoComplete="off"
														name="confirmpassword"
														className="w-full px-3 py-2 m-0 mt-2 border-2 rounded border-neutral-500 "
													/>
												</label>
												{formik.touched.confirmpassword && (
													<div className="mt-2 text-error-500">
														{formik.errors.confirmpassword}
													</div>
												)}
											</div>
										)}
										<Button
											type="submit"
											loading={submitting}
											size="lg"
											disabled={
												submitting ||
												((isDisabled(formik.values) ||
													formik.values.password !==
														formik.values.confirmpassword) &&
													!isSignIn)
											}
											text={isSignIn ? 'Login' : 'Complete signup'}
										/>
										{error && <div>{error}</div>}
									</form>
								);
							}}
						</Formik>
					</>
				)}
			</div>
		</product.Layout>
	);
};
