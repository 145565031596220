import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import config from '../../utils/config';

const { environment } = config();

const withRecaptcha = (WrappedComponent) => {
	return (props) => (
		<GoogleReCaptchaProvider
			reCaptchaKey={
				environment === 'prod'
					? '6LfHCE4gAAAAACR41RWg9V1iKKkl81YIdCIZTYF9'
					: '6LeZDhYgAAAAAGc9iS5zEUvHgypU-Jn7IEpg-0vL'
			}
		>
			<WrappedComponent {...props} />
		</GoogleReCaptchaProvider>
	);
};

export default withRecaptcha;
