/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable max-params */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-expressions */
export default () => {
	!(function (f, b, e, v, n, t, s) {
		if (f.fbq) return;
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = '2.0';
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
	window.fbq('init', '1658535551099034');
	window.fbq('track', 'PageView');
};
/* eslint-enable func-names */
/* eslint-enable max-params */
/* eslint-enable no-underscore-dangle */
/* eslint-enable prefer-destructuring */
/* eslint-enable prefer-rest-params */
/* eslint-enable prefer-spread */
/* eslint-enable no-multi-assign */
/* eslint-enable no-unused-expressions */
/* eslint-enable no-param-reassign */
