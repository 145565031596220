import React from 'react';

export default () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
		<defs>
			<linearGradient
				id="a"
				x1=".912"
				y1=".046"
				x2="-.227"
				y2=".64"
				gradientUnits="objectBoundingBox"
			>
				<stop offset="0" stopColor="#00a0ff" />
				<stop offset=".007" stopColor="#00a1ff" />
				<stop offset=".26" stopColor="#00beff" />
				<stop offset=".512" stopColor="#00d2ff" />
				<stop offset=".76" stopColor="#00dfff" />
				<stop offset="1" stopColor="#00e3ff" />
			</linearGradient>
			<linearGradient
				id="b"
				x1=".99"
				y1=".5"
				x2="-.92"
				y2=".5"
				gradientUnits="objectBoundingBox"
			>
				<stop offset="0" stopColor="#ffe000" />
				<stop offset=".409" stopColor="#ffbd00" />
				<stop offset=".775" stopColor="orange" />
				<stop offset="1" stopColor="#ff9c00" />
			</linearGradient>
			<linearGradient
				id="c"
				x1=".99"
				y1=".013"
				x2="-.28"
				y2="1.662"
				gradientUnits="objectBoundingBox"
			>
				<stop offset="0" stopColor="#ff3a44" />
				<stop offset="1" stopColor="#c31162" />
			</linearGradient>
			<linearGradient
				id="d"
				x1="-.027"
				y1="-.319"
				x2=".41"
				y2=".243"
				gradientUnits="objectBoundingBox"
			>
				<stop offset="0" stopColor="#32a071" />
				<stop offset=".068" stopColor="#2da771" />
				<stop offset=".476" stopColor="#15cf74" />
				<stop offset=".801" stopColor="#06e775" />
				<stop offset="1" stopColor="#00f076" />
			</linearGradient>
		</defs>
		<path fill="none" d="M0 0h24v24H0z" />
		<path
			d="M20.393 88.677l-.059-.056a1.567 1.567 0 01-.362-1.1V70.458a1.51 1.51 0 01.419-1.157l9.7 9.689-9.695 9.688"
			transform="translate(-16.973 -66.932)"
			fill="url(#a)"
		/>
		<path
			d="M36.131 84.04L32.9 80.8l3.233-3.24 3.9 2.222a1.461 1.461 0 01.82 1.017 1.461 1.461 0 01-.82 1.017l-3.9 2.222"
			transform="translate(-19.853 -68.743)"
			fill="url(#b)"
		/>
		<path
			d="M21.2 91.663a1.006 1.006 0 01-.691-.255l9.691-9.717 3.233 3.24L22.078 91.4a1.8 1.8 0 01-.877.264"
			transform="translate(-17.09 -69.663)"
			fill="url(#c)"
		/>
		<path
			d="M30.2 78.917l-9.7-9.718a1.006 1.006 0 01.691-.254 1.806 1.806 0 01.878.264l11.36 6.468-3.229 3.24"
			transform="translate(-17.09 -66.831)"
			fill="url(#d)"
		/>
	</svg>
);
