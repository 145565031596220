import React, { useState, useEffect } from 'react';

const SuccessfulUpdate = ({ returnUrl }) => {
	const [seconds, setSeconds] = useState(5);

	if (!seconds) window.location.assign(returnUrl);

	useEffect(() => {
		setInterval(() => {
			setSeconds((s) => {
				return !s ? s : s - 1;
			});
		}, 1000);
	}, []);

	return (
		<div className="text-center lg:m-12 lg:p-4">
			<div className="text-2xl font-semibold text-primary-400" data-testid="success-message">
				Password changed!
			</div>
			<div className="mt-8 mb-12 text-lg">
				Remember, this will have affected any other services provided by Bright.
			</div>
			<div className="mt-20 text-3xl text-primary-400">Redirecting in... {seconds}</div>
		</div>
	);
};

export default SuccessfulUpdate;
