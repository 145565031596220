import React, { useState, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';
import initializeFBPixel from 'utils/initializeFBPixel';
import register from '../../services/register';
import withRecaptcha from '../../services/withRecaptcha';
import SignupForm from './SignupForm';
import HRLiteLayout from '../../layouts/HRLite';
import SafeLiteLayout from '../../layouts/SafeLite';

const FreemiumSignUp = () => {
	const [registeredEmail, setRegisteredEmail] = useState('');
	const [screen, setScreen] = useState('sign-up');
	const { search } = useLocation();
	const isSignUpScreen = screen === 'sign-up';
	const { executeRecaptcha } = useGoogleReCaptcha();
	const URLParams = new URLSearchParams(search);

	const product = URLParams.get('product');
	const isBrightSafe = product === 'brightsafe';

	const handleReCaptchaVerify = useCallback(async () => {
		const token = await executeRecaptcha('register');
		return token;
	}, [executeRecaptcha]);

	useEffect(() => {
		initializeFBPixel();
	}, []);

	const onSignup = async (values) => {
		const utmQueries = Array.from(URLParams).reduce((acc, [key, value]) => {
			if (key.startsWith('utm')) {
				const withoutUTMPrefix = key.substr(4);

				return { ...acc, [withoutUTMPrefix]: value };
			}

			return acc;
		}, {});
		const gclidQueries = Array.from(URLParams)
			.filter(([key]) => key === 'gclid')
			.map(([, value]) => value);
		const recaptchaToken = await handleReCaptchaVerify();
		const countryName = 'United Kingdom';
		const reducedValues = {
			firstName: values.firstName.trim(),
			lastName: values.lastName.trim(),
			email: values.email.trim(),
			companyName: values.companyName.trim(),
		};
		register({
			data: {
				...reducedValues,
				utm: utmQueries,
				gclid: gclidQueries[0] || '',
				origin: isBrightSafe ? 'BrightSafe Lite' : 'BrightHR Lite',
				countryName,
				recaptchaToken,
			},
		}).then((response) => {
			const { status, data } = response;
			if (status === 200) {
				const { registrationId } = data;
				window.location = `/signup/${registrationId}`;
			} else {
				setScreen('thank-you');
				setRegisteredEmail(values.email);
			}
		});
	};

	const Layout = isBrightSafe ? SafeLiteLayout : HRLiteLayout;

	return (
		<Layout brightSafe={isBrightSafe}>
			<div className="max-w-screen-lg">
				{isSignUpScreen && (
					<SignupForm
						onSignup={onSignup}
						isPaywallModal={false}
						brightSafe={isBrightSafe}
					/>
				)}
				{!isSignUpScreen && (
					<div className="flex items-center justify-center h-full transition-all animate-appear">
						<div className="flex flex-col items-center justify-center space-y-2">
							<p>Thanks for registering!</p>
							<p className="pb-6 text-2xl text-center md:text-3xl">
								Please check {registeredEmail} and complete your registration
							</p>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default withRecaptcha(FreemiumSignUp);
