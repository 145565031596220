import React from 'react';
import { Formik, Form } from 'formik';
import { Modal, ModalBody, ModalFooter } from '@brighthr/component-modal';
import Button from '@brighthr/component-button';
import Alert from '@brighthr/component-alert';
import FormikInput from 'components/FormikUI/FormikInput';
import FormikSelect from 'components/FormikUI/FormikSelect';
import FormikValidation from 'components/FormikValidation';

import schema from './schema';

const AddDelegateModal = ({
	isOpen,
	closeModal,
	domains,
	handleAddSubmit,
	doesDelegateExist,
	setDoesDelegateExist,
	isNotConsultant,
	setIsNotConsultant,
	isAddFormLoading,
	ga,
}) => {
	if (!isOpen) return null;

	return (
		<Modal
			close={() => closeModal(false)}
			title="Add a delegate"
			headerSize="lg"
			color="primary"
			width="base"
		>
			<Formik
				initialValues={{
					email: '',
					domain: '',
					finalEmail: '',
				}}
				onSubmit={handleAddSubmit}
				validationSchema={schema}
			>
				{({ setFieldValue, values, errors }) => {
					const allErrors = {
						...errors,
						doesDelegateExist,
						isNotConsultant,
					};

					return (
						<Form>
							<ModalBody>
								<Alert type="information" fullWidth>
									Adding a delegate will mean they automatically have access to
									your company space.
								</Alert>
								<h2 className="my-4 text-neutral-700">Enter delegation email</h2>
								<label htmlFor="email" className="w-full font-semibold">
									Email address
								</label>
								<div className="flex flex-wrap">
									<div className="w-full mr-0 lg:mr-2 lg:w-[40%] mb-0">
										<FormikInput
											id="email"
											name="email"
											fullWidth
											onFocus={() => {
												ga('Add delegate email address');
											}}
											onChange={(e) => {
												setFieldValue(
													'finalEmail',
													e.target.value + values.domain
												);
												setFieldValue('email', e.target.value);
												setDoesDelegateExist(false);
												setIsNotConsultant(false);
											}}
										/>
									</div>
									<div className="w-full lg:w-[30%]">
										<FormikSelect
											id="domain"
											name="domain"
											data-testid="domain"
											placeholder="Search or select"
											fullWidth
											onChange={(e) => {
												setFieldValue(
													'finalEmail',
													values.email + e.target.value
												);
												setFieldValue('domain', e.target.value);
												setDoesDelegateExist(false);
												setIsNotConsultant(false);
											}}
										>
											<option value="" disabled>
												Select domain
											</option>
											{domains?.map((domain) => {
												return (
													<option key={domain} value={domain}>
														{domain}
													</option>
												);
											})}
										</FormikSelect>
									</div>
									<FormikValidation errors={allErrors} />
								</div>
							</ModalBody>
							<ModalFooter>
								<Button
									text="Cancel"
									outlineButton
									onClick={() => {
										ga('Add delegate Cancel');
										closeModal(false);
									}}
								/>
								<Button
									text="Add delegate"
									type="submit"
									disabled={isAddFormLoading}
									isLoading={isAddFormLoading}
									onClick={() => {
										ga('Add delegate Confirm');
									}}
								/>
							</ModalFooter>
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default AddDelegateModal;
