import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';

import config from 'utils/config';
import Layout from '../../../layouts';

const ErrorMessage = ({ message, ButtonComp }) => {
	const { AUTHORISATION_SERVER_URL } = config();
	const forgottenURL = `${AUTHORISATION_SERVER_URL}/account/password-reset`;

	return (
		<Layout>
			<div className="flex flex-col items-center text-center">
				<h1 className="m-0 text-6xl text-primary-200 sm:text-9xl">Sorry</h1>
				<h2 className="mb-4 text-2xl text-neutral-700">{message}</h2>

				{ButtonComp ? (
					<ButtonComp />
				) : (
					<LinkButton href={forgottenURL} text="Request a new reset password link" />
				)}
			</div>
		</Layout>
	);
};

export default ErrorMessage;
