import React, { useState } from 'react';
import StyledTab from '@brighthr/component-styled-tab';
import isAdmin from 'utils/roles/isAdmin';
import { event } from 'utils/GA';
import Security from './Components/security';
import Delegaton from './Components/delegation';

export default () => {
	const [tabIndex, setTabIndex] = useState(0);

	const SecurityPageComponents = {
		0: Security,
		1: Delegaton,
	};

	const Component = SecurityPageComponents[tabIndex];

	const accountSecurityName = 'Account security';
	const padlockIcon = 'padlock-locked';
	const tabClassName = 'lg:w-1/8';

	return (
		<>
			<StyledTab
				hasBorder={false}
				onSelect={(index) => {
					event({
						category: 'My Account',
						action: index === 0 ? 'Account security Tab' : 'Account access Tab',
					});
					setTabIndex(index);
				}}
				tabIndex={tabIndex}
				tabs={
					isAdmin()
						? [
								{
									className: 'lg:w-1/8 ml-4 lg:ml-0',
									iconName: padlockIcon,
									name: accountSecurityName,
								},
								{
									className: tabClassName,
									iconName: 'users',
									name: 'Account access',
								},
						  ]
						: [
								{
									className: tabClassName,
									iconName: padlockIcon,
									name: accountSecurityName,
								},
						  ]
				}
			>
				<Component />
			</StyledTab>
		</>
	);
};
