import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import { event } from 'utils/GA';
import passwordShield from './assets/passwordShield.svg';

const PasswordManagement = () => {
	return (
		<div className="flex flex-col p-5 my-4 mb-4 border-2 md:flex-row border-primary-100 rounded-2xl">
			<img src={passwordShield} alt="password icon" className="m-auto" />
			<div className="flex flex-col items-center justify-between md:flex-row">
				<div className="ml-4">
					<h2 className="inline-block mb-2 mr-2 text-lg font-bold text-neutral-700">
						Password management
					</h2>
					<p>
						Update your password, changing your password will affect any other services
						provided by Bright.
					</p>
				</div>

				<div className="w-full mt-5 whitespace-nowrap md:w-auto md:ml-3">
					<LinkButton
						color="accent"
						size="base"
						text="Change Password"
						href="/change-password"
						onClick={() =>
							event({
								category: 'My Account',
								action: 'Change password',
							})
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default PasswordManagement;
