import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import { logout } from 'hooks/useAuthentication';
import config from 'utils/config';
import { event } from 'utils/GA';
import Layout from '../../layouts';

const ga = (action) =>
	event({
		category: 'Restrict access',
		action,
	});

export default () => {
	const { AUTHORISATION_SERVER_URL } = config();
	const logoutUrl = `${AUTHORISATION_SERVER_URL}/account/logout`;

	return (
		<Layout>
			<div className="items-center p-5 border-2 rounded border-primary-100">
				<h1 className="mb-4 text-2xl font-bold text-primary-700">
					Oops! Looks like you’re in the wrong place…
				</h1>
				<div className="flex items-center">
					<div className="mr-3 space-y-3">
						<p>
							You unfortunately don’t have permission to access this product, as it’s
							not included in your package.
						</p>
					</div>
					<LinkButton
						href={logoutUrl}
						onClick={() => {
							ga('Logout');
							logout();
						}}
						text="Logout"
					/>
				</div>
			</div>
			<div className="pl-5 mt-4">
				<p>Got a question about what’s included in your package?</p>
				<p>
					Call one of our friendly experts today on{' '}
					<a
						href="tel:0800 470 2432"
						className="text-primary-700"
						onClick={() => ga('Call')}
					>
						0800 470 2432
					</a>{' '}
					(option 1).
				</p>
			</div>
		</Layout>
	);
};
