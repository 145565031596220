import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import Icon from '@brighthr/component-icon';
import isAdmin from 'utils/roles/isAdmin';
import Logo from '../logo';
import ProfileImage from './ProfileImage';
import AppSwitcher from './AppSwitcher';
import getToken from '../../utils/getToken';

const Layout = ({ children }) => {
	const { pathname } = useLocation();
	const isFreeUser = getToken().package === 'free';

	return (
		<>
			<div className="mb-2 border-b bg-primary-50 border-primary-100">
				<header className="max-w-screen-lg m-auto">
					<div className="flex justify-between p-2 md:p-4">
						<Link to="/" aria-label="Back to home">
							<Logo
								className="w-32 mx-auto my-0 fill-current md:m-0 text-primary-400"
								role="img"
							/>
						</Link>
					</div>

					<nav className="flex justify-between bg-white border border-b-0 rounded-t-lg border-primary-100">
						<div className="flex">
							<Link
								className={classnames({
									'pl-6 sm:px-6 no-underline hover:text-primary-400 flex items-center': true,
									'font-medium': pathname.includes('/security'),
								})}
								to="/security"
							>
								<Icon
									iconName="home"
									size={36}
									className="mr-3 fill-primary-400"
									aria-label="Home"
								/>
								<span className="hidden sm:inline">My Account</span>
							</Link>
							{isAdmin() && (
								<Link
									className={classnames({
										'px-6 no-underline hover:text-primary-400 flex items-center': true,
										'font-medium': pathname.includes('/security'),
									})}
									to="/2fa-report"
								>
									<Icon
										iconName="security-lock"
										size={36}
										className="mr-3 fill-primary-400"
									/>
									<span className=" md:inline">2FA status</span>
								</Link>
							)}{' '}
						</div>

						<div className="flex items-center space-x-4">
							{!isFreeUser && <AppSwitcher />}
							<ProfileImage />
						</div>
					</nav>
				</header>
			</div>

			<main className="max-w-screen-lg p-2 pb-8 mx-auto my-0 md:pb-12 md:p-4">
				{children}
			</main>
		</>
	);
};

export default Layout;
