import React from 'react';

const NoRecordsMessage = ({ logo, text }) => {
	return (
		<div className="h-[40vh] flex justify-center items-center">
			<div className="text-center">
				<img src={logo} alt="no records" className="mx-auto mb-5 max-w-[300px]" />
				<div className="flex max-w-[400px] mb-2 text-base text-neutral-600">{text}</div>
			</div>
		</div>
	);
};

export default NoRecordsMessage;
