import { useHistory, useLocation } from 'react-router-dom';
import { Modal, ModalBody } from '@brighthr/component-modal';

import Button from '@brighthr/component-button';
import LinkButton from '@brighthr/component-linkbutton';
import React, { useState } from 'react';
import cn from 'classnames';
import ssoUnlink from 'services/sso/unlink';
import useProviderStatus from 'services/sso/status/useProviderStatus';
import getToken from 'utils/getToken';
import { event } from 'utils/GA';
import NotFound from 'pages/Errors/NotFound';
import GoogleLogo from './assets/google-logo.svg';
import MicrosoftLogo from './assets/microsoft-logo.svg';
import AppleLogo from './assets/apple-logo.svg';
import FacebookLogo from './assets/facebook-logo.svg';
import Layout from '../../layouts/SSO';
import SetupStep from './setup';
import ConfirmStep from './confirm';

import { UNLINK_ERROR, EMAIL_UNAVAILABLE, CONSENT_ERROR, EMAIL_NOT_SHARED } from './constants';

const ga = (action, label) => {
	const eventArgs = {
		category: 'SSO setup',
		action,
	};

	if (label) eventArgs.label = label;

	return event(eventArgs);
};

const capitalizeString = (string = '') => {
	return string?.charAt(0).toUpperCase() + string?.substring(1).toLowerCase();
};

const SingleSignOn = () => {
	const qs = new URLSearchParams(window.location.search);
	const path = useLocation().pathname;
	const history = useHistory();
	const { sub: userId } = getToken();
	const qsError = qs.get('error');
	const qsProvider = qs.get('provider');
	const qsIdp = qs.get('idp');

	const validErrorStates = [UNLINK_ERROR, CONSENT_ERROR, EMAIL_UNAVAILABLE, EMAIL_NOT_SHARED];

	const [showUnlinkModal, setShowUnlinkModal] = useState(false);
	const [providerToUnlink, setProviderToUnlink] = useState(null);
	const [errorState, setErrorState] = useState(qsError || null);

	// Array of providers -> Google, Microsoft
	const idpStatuses = useProviderStatus(userId, {
		refetchInterval: 5000,
		refetchOnWindowFocus: false,
	});

	const ssoProviders = [
		{ name: 'Google', logo: GoogleLogo },
		{ name: 'Microsoft', logo: MicrosoftLogo },
		{ name: 'Apple', logo: AppleLogo },
		{ name: 'Facebook', logo: FacebookLogo },
	];
	const providerIndex = ssoProviders.findIndex(
		(p) => p.name.toLowerCase() === qsProvider?.toLowerCase()
	);

	const providerLinked = {
		...ssoProviders?.[providerIndex],
		...idpStatuses?.[providerIndex]?.data,
	};
	const isConfirmationStep = path === '/sso/confirm' && providerLinked;

	if (isConfirmationStep && !providerLinked?.name) {
		return <NotFound />;
	}

	const handleUnlink = (provider) => {
		setShowUnlinkModal(true);
		setProviderToUnlink(provider);
	};

	return (
		<>
			{validErrorStates.includes(errorState) && (
				<div
					className="fixed left-0 right-0 w-3/4 px-8 py-4 mx-auto mt-8 text-white rounded bg-error-700 sm:w-1/2"
					role="alert"
				>
					<div className="grid grid-cols-12 gap-4">
						{errorState === UNLINK_ERROR && (
							<h3 className="col-span-9 sm:col-span-10">
								Sorry, we couldn&apos;t unlink your{' '}
								{providerToUnlink ? `${providerToUnlink} ` : ''}account right now,
								please try again later.
							</h3>
						)}
						{errorState === CONSENT_ERROR && (
							<h3 className="col-span-9 sm:col-span-10">
								In order to set up single sign-on and link your accounts,
								you&apos;ll need to allow BrightHR access to your{' '}
								{capitalizeString(qsIdp) || ''} account.
							</h3>
						)}
						{errorState === EMAIL_UNAVAILABLE && (
							<h3 className="col-span-9 sm:col-span-10">
								Sorry, this email address is already in use and linked to your{' '}
								{capitalizeString(qsIdp) || ''} account.
							</h3>
						)}
						{errorState === EMAIL_NOT_SHARED && (
							<h3 className="col-span-9 sm:col-span-10">
								Sorry, we could not link your Facebook account as the email address
								data was not shared. Please remove the connection from your{' '}
								<a
									className="text-white"
									href="https://www.facebook.com/settings?tab=applications"
									target="_blank"
									rel="noreferrer"
								>
									Facebook Settings
								</a>{' '}
								and then try again.
							</h3>
						)}
						<Button
							color="error"
							onClick={() => {
								setErrorState(null);
								qs.delete('error');
								qs.delete('idp');
								history.replace({
									search: qs.toString(),
								});
							}}
							text="Dismiss"
						/>
					</div>
				</div>
			)}
			<Layout>
				{!isConfirmationStep && (
					<SetupStep
						ssoProviders={ssoProviders}
						ssoStatus={idpStatuses}
						handleUnlink={handleUnlink}
						ga={ga}
					/>
				)}
				{isConfirmationStep && (
					<ConfirmStep
						providerLinked={providerLinked}
						handleUnlink={handleUnlink}
						ga={ga}
					/>
				)}
				{showUnlinkModal && (
					<Modal close={() => setShowUnlinkModal(false)}>
						<ModalBody>
							<h3 className="mb-4 text-2xl font-bold text-error-700">
								Unlink your {providerToUnlink} Account
							</h3>
							<p className="mb-12">
								Are you sure? Unlinking your account means you won&apos;t be able to
								log in to BrightHR using your {providerToUnlink} account. Plus,
								you&apos;ll have to start from scratch if you want to set up single
								sign-on again.
							</p>
							<div className="flex justify-between">
								<Button
									outlineButton
									onClick={() => {
										ga('Unlink Cancel');
										setShowUnlinkModal(false);
									}}
									text="Cancel"
								/>
								<Button
									onClick={() => {
										ga('Unlink I understand');
										ssoUnlink(providerToUnlink).then((res) => {
											if (res instanceof Error) setErrorState(UNLINK_ERROR);
											else {
												const idx = ssoProviders.findIndex(
													(ssoProvider) =>
														ssoProvider?.name === providerToUnlink
												);
												idpStatuses[idx].refetch();
											}
										});
										setShowUnlinkModal(false);
									}}
									text="I understand"
								/>
							</div>
						</ModalBody>
					</Modal>
				)}
				<div
					className={cn(
						'flex justify-between fixed bg-primary-50 inset-x-0 bottom-0 px-6 py-4 items-center flex-row',
						{
							'flex-row-reverse': isConfirmationStep,
						}
					)}
				>
					{isConfirmationStep ? (
						<LinkButton href="/sso" text="Finish" onClick={() => ga('Finish')} />
					) : (
						<LinkButton
							outlineButton
							text="Exit setup"
							href="/"
							onClick={() => ga('Exit Setup')}
						/>
					)}
				</div>
			</Layout>
		</>
	);
};

export default SingleSignOn;
