import React, { forwardRef } from 'react';

const RecoveryCodes = forwardRef(({ recoveryCodes }, ref) => {
	return (
		<ul className="text-center" ref={ref}>
			<h1 className="hidden print:block mt-4">Your recovery codes</h1>
			<p className="mb-4 font-bold">Keep these in a safe place</p>
			{recoveryCodes.map((code) => (
				<li
					key={code}
					className="inline-block w-full px-4 py-1 mb-1 mr-5 even:mr-0 font-mono sm:w-5/12 bg-primary-50 print:bg-transparent"
				>
					{code}
				</li>
			))}
		</ul>
	);
});

export default RecoveryCodes;
