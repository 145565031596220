export default () => {
	const userToken = window.localStorage.getItem('userToken');
	const expiryTime = window.localStorage.getItem('userTokenTimeout');

	if (userToken) {
		return expiryTime > Date.now();
	}

	return false;
};
