import React from 'react';
import Button from '@brighthr/component-button';

export default () => (
	<div>
		<div className="flex flex-col items-center text-center">
			<h1 className="m-4 text-6xl text-primary-200 sm:text-9xl">Sorry</h1>
			<h2 className="mb-12 text-2xl text-neutral-700">An error has occured...</h2>
			<Button
				onClick={() => {
					window.location.reload();
				}}
				text="Refresh page"
			/>
		</div>
	</div>
);
