import getToken from 'utils/getToken';
import brightSession from 'utils/brightSession';
import config from 'utils/config';

export default () => {
	const { sub: guid, brighthr_app_region: country, company_id: companyId } = getToken();

	if (window.TrackJS && guid) {
		const { BUILD_VERSION: BuildVersion = '' } = config();

		window.TrackJS.configure({
			version: BuildVersion.toString(),
			sessionId: brightSession,
			userId: guid,
		});

		if (country) {
			window.TrackJS.addMetadata('country', country);
		}
		if (companyId) {
			window.TrackJS.addMetadata('company', companyId);
		}
	}
};
