import React from 'react';
import svg from './brightHRlite.svg';

const Layout = ({ children }) => (
	<div className="flex flex-col items-center lg:items-center lg:flex-row">
		<div
			className="flex-none self-start w-[40%] min-w-[600px] h-screen min-h-[775px] bg-no-repeat bg-center bg-cover hidden lg:inline-flex"
			style={{ backgroundImage: `url(${svg})` }}
			alt="BrightHR Lite"
		/>
		<div className="flex flex-row justify-center m-2 grow">{children}</div>
	</div>
);

export default Layout;
