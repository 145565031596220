import http from 'utils/http';
import config from 'utils/config';

const setup = () => {
	const { ACCOUNT_API } = config();
	const uri = `${ACCOUNT_API}/v1/account/authenticator`;

	return http.delete(uri).then((res) => res.data);
};

export default setup;
