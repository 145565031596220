import React from 'react';
import Layout from 'layouts';
import LinkButton from '@brighthr/component-linkbutton';

const NotFound = () => (
	<Layout>
		<div className="flex flex-col items-center text-center">
			<h1 className="m-0 text-6xl text-primary-200 sm:text-9xl">Oops!</h1>
			<h2 className="mb-8 text-2xl text-neutral-700">
				The page you&apos;re trying to access doesn&apos;t exist
			</h2>
			<LinkButton href="/" text="Home" />
		</div>
	</Layout>
);

export default NotFound;
