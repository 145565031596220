import React from 'react';
import twoFactorAuthReport from 'services/2fa/report';
import allEmployees from 'services/employee/allEmployees';
import { useQuery } from 'react-query';
import Avatar from '@brighthr/component-avatar';
import Icon from '@brighthr/component-icon';
import Layout from '../../layouts/AccountHeader';

const TwoFactorAuthAdmin = () => {
	const { isLoading: loadingReport, data: report } = useQuery(['report'], twoFactorAuthReport);
	const { isLoading: loadingEmployees, data: employees } = useQuery(
		['allEmployees'],
		allEmployees,
		{
			select: (data) => {
				return data.reduce((list, { id, firstName, lastName, profileImage, jobTitle }) => {
					return {
						...list,
						[id]: { name: `${firstName} ${lastName}`, profileImage, jobTitle },
					};
				}, {});
			},
		}
	);

	if (loadingReport || loadingEmployees) {
		return null;
	}

	return (
		<Layout>
			<ul>
				{report.map(({ multiFactorState, id }) => {
					if (!employees[id]) {
						return null;
					}
					const { profileImage, name, jobTitle } = employees[id];
					const { enabled } = multiFactorState;

					return (
						<li key={id} className="p-3 mb-3 border rounded-md border-primary-100">
							<div className="flex items-center justify-between">
								<div className="flex items-center ">
									<Avatar imageHref={profileImage} name={name} size="base" />
									<div className="ml-4">
										<div className="text-lg">{name}</div>
										<div>{jobTitle}</div>
									</div>
								</div>
								<div>
									{enabled && (
										<Icon
											className="fill-success-500"
											iconName="tick-circle"
											size={40}
										/>
									)}
									{!enabled && (
										<Icon
											className="fill-error-500"
											iconName="cross-circle"
											size={40}
										/>
									)}
								</div>
							</div>
						</li>
					);
				})}
			</ul>
		</Layout>
	);
};

export default TwoFactorAuthAdmin;
