import React from 'react';
import { Link } from 'react-router-dom';

import Logo from './logo';

const Layout = ({ children }) => {
	return (
		<>
			<header className="border-b-2 bg-primary-50 border-primary-100 md:mb-8">
				<div className="max-w-screen-lg p-2 mx-auto md:p-4">
					<Link to="/" aria-label="Back to home">
						<Logo className="w-32 mx-auto my-0 fill-current md:m-0 text-primary-400" />
					</Link>
				</div>
			</header>
			<main className="max-w-screen-lg p-2 pb-24 mx-auto my-0 md:p-4">{children}</main>
		</>
	);
};

export default Layout;
