import React from 'react';
import { Field, ErrorMessage } from 'formik';
import Select from '@brighthr/component-select';

const FormikSelect = (props) => (
	<div className="my-2 text-error-400">
		<Field name={props.name}>
			{({ field, meta }) => (
				<Select
					className=" h-[42px]"
					error={Boolean(meta?.error) && meta?.touched}
					{...field}
					{...props}
				/>
			)}
		</Field>

		<div className="mt-2">
			<ErrorMessage name={props.name} />
		</div>
	</div>
);

export default FormikSelect;
