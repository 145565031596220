import React from 'react';
import Spinner from '@brighthr/component-spinner';

const LoadingContainer = ({ loading, children }) => {
	return (
		<>
			{loading ? (
				<div className="pt-8 pb-8 text-center">
					<Spinner
						ariaLabel="loading content"
						className="m-auto stroke-primary-700"
						size="xxl"
					/>
				</div>
			) : (
				<>{children}</>
			)}
		</>
	);
};

export default LoadingContainer;
