import React from 'react';

const FormikValidation = ({ errors }) => (
	<>
		{errors.finalEmail && <p className="text-error-400">{errors.finalEmail}</p>}
		{errors.doesDelegateExist && (
			<p className="w-full text-error-400">User is already a delegate</p>
		)}
		{errors.isNotConsultant && (
			<p className="w-full text-error-400">User is not a registered consultant</p>
		)}
	</>
);

export default FormikValidation;
