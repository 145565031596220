import React from 'react';
import ErrorLayout from 'pages/Errors/500';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch() {
		// trackjs will catch this anyway
	}

	render() {
		if (this.state.hasError) {
			return <ErrorLayout />;
		}
		return this.props.children;
	}
}
export default ErrorBoundary;
