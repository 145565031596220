import React, { useState } from 'react';
import Button from '@brighthr/component-button';

import { Formik } from 'formik';
import { event } from 'utils/GA';
import SuccessfulUpdate from './SuccessfulUpdate';
import PasswordFields from '../PasswordFields';
import Layout from '../../../layouts/AccountHeader';
import changePassword from '../../../services/changePassword';
import ErrorMessage from '../ErrorMessage';
import InputBox from '../PasswordFields/InputBox';

const ChangePassword = () => {
	const [successfulUpdate, setSuccessfulUpdate] = useState(false);
	const [resetFailed, setResetFailed] = useState(false);
	const [passwordError, setPasswordError] = useState('');
	const [passwordInput, setPasswordInput] = useState('password');

	if (resetFailed) {
		return <ErrorMessage message="Something went wrong." />;
	}

	if (passwordError) {
		return (
			<ErrorMessage
				message={`${passwordError} Please try again.`}
				ButtonComp={() => <Button onClick={() => setPasswordError('')} text="Try again" />}
			/>
		);
	}

	if (successfulUpdate) {
		return (
			<Layout>
				<SuccessfulUpdate>Password reset</SuccessfulUpdate>
			</Layout>
		);
	}

	const shouldDisableButton = (validation) =>
		!(
			validation.validation0 &&
			validation.validation1 &&
			validation.validation2 &&
			validation.validation3
		);

	return (
		<Layout>
			<Formik
				initialValues={{
					currentpassword: '',
					newpassword: '',
					confirmpassword: '',
					validation: {
						validation0: undefined,
						validation1: undefined,
						validation2: undefined,
					},
				}}
				onSubmit={(values) => {
					changePassword(values.currentpassword, values.newpassword)
						.then(() => setSuccessfulUpdate(true))
						.catch(({ response: { status, data: { detail } } }) =>
							status === 409 ? setPasswordError(detail) : setResetFailed(true)
						);
					event({
						category: 'Change password',
						action: 'Change password',
					});
				}}
			>
				{(formik, { values } = formik) => (
					<div className="flex justify-center">
						<form className="sm:w-2/4" onSubmit={formik.handleSubmit}>
							<h1 className="my-6 text-neutral-700 ">Change your password</h1>
							<InputBox
								passwordInput={passwordInput}
								setPasswordInput={setPasswordInput}
								val={values.currentpassword}
								label="Current password"
								name="currentpassword"
								onChangeValidate={formik.handleChange}
								actionName="Current password"
								categoryName="Change password"
							/>
							<PasswordFields formik={formik} categoryName="Change password" />
							<div className="flex my-8 mr-2">
								<Button
									data-testid="change-password"
									type="submit"
									loading={formik.isSubmitting}
									disabled={
										shouldDisableButton(values.validation) ||
										formik.isSubmitting ||
										!values.currentpassword
									}
									text="Change password"
								/>
							</div>
						</form>
					</div>
				)}
			</Formik>
		</Layout>
	);
};

export default ChangePassword;
