import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import { event } from 'utils/GA';

import delegationImage from './assets/delegation.png';

const Delegation = () => {
	return (
		<div className="my-4 border-2 border-primary-100 rounded-2xl">
			<div className="items-center p-5 my-4 space-y-6 md:flex">
				<img
					src={delegationImage}
					alt="delegation icon"
					className="m-auto lg:w-56 lg:h-36"
				/>
				<div className="ml-4">
					<h2 className="inline-block mb-2 mr-2 text-lg font-bold text-neutral-700">
						Delegate access
					</h2>
					<div className="inline-flex px-2 text-xs tracking-wider rounded-full bg-success-100 text-success-900">
						NEW
					</div>
					<p>
						Delegate access to our support staff so that they can access your company
						and assist you with all your needs.
					</p>
				</div>
				<div className="w-full whitespace-nowrap md:w-auto md:ml-3">
					<LinkButton
						color="accent"
						size="base"
						text="Manage delegation"
						href="/manage-delegation"
						onClick={() =>
							event({
								category: 'My Account',
								action: 'Manage delegation',
							})
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default Delegation;
