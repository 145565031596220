import React from 'react';
import { Modal, ModalBody, ModalFooter } from '@brighthr/component-modal';
import Alert from '@brighthr/component-alert';
import Button from '@brighthr/component-button';

const DeleteDelegateModal = ({
	isOpen,
	closeModal,
	deleteDelegateInfo,
	handleDeleteSubmit,
	isDeleteFormLoading,
	isDeleteDelegateError,
	ga,
}) => {
	if (!isOpen) return null;

	return (
		<Modal
			close={() => closeModal(false)}
			title="Remove delegate"
			headerSize="lg"
			color="primary"
			width="base"
		>
			<ModalBody>
				{isDeleteDelegateError && (
					<div className="mb-4">
						<Alert type="error" icon="error" title="Error" border dismiss fullWidth>
							Something has gone wrong
						</Alert>
					</div>
				)}
				<p>
					Are you sure you want to remove <b>{deleteDelegateInfo.name}</b> from your list
					of delegates? This will mean they no longer have access to your account. You can
					undo this action by adding in the delegate again.
				</p>
			</ModalBody>
			<ModalFooter>
				<Button
					text="Cancel"
					outlineButton
					onClick={() => {
						ga('Remove delegate Cancel');
						closeModal(false);
					}}
				/>
				<Button
					text="Remove delegate"
					onClick={() => {
						ga('Remove delegate Confirm');
						handleDeleteSubmit();
					}}
					disabled={isDeleteFormLoading}
					isLoading={isDeleteFormLoading}
				/>
			</ModalFooter>
		</Modal>
	);
};

export default DeleteDelegateModal;
