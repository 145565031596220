import React from 'react';
import { Field, ErrorMessage } from 'formik';
import InputUI from '@brighthr/component-input';

const FormikInput = (props) => {
	const { name } = props;
	return (
		<div className="my-2 text-error-400">
			<Field name={name}>
				{({ field, meta }) => (
					<InputUI error={Boolean(meta?.error) && meta?.touched} {...field} {...props} />
				)}
			</Field>
			<div className="mt-2">
				<ErrorMessage name={name} />
			</div>
		</div>
	);
};

export default FormikInput;
