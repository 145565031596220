import http from 'utils/http';
import config from 'utils/config';

const status = (provider) => {
	const { AUTHORISATION_SERVER_URL: authServerAPI } = config();
	const uri = `${authServerAPI}/sso/${provider}/status`;

	return http
		.get(uri)
		.then((res) => {
			if (res.status === 204) return { id: null, email: null, usedForSignup: false };
			return res.data;
		})
		.catch((err) => err);
};

export default status;
