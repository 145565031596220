import React, { useRef } from 'react';
import RecoveryCodes from '../components/RecoveryCodes';
import CodeOptions from '../components/RecoveryCodes/CodeOptions';

export default ({ recoveryCodes }) => {
	const componentRef = useRef();

	return (
		<div className="flex flex-col text-center md:flex-row md:text-left">
			<div className="max-w-screen-md px-8 mb-8 space-y-6 md:mb-0 md:w-1/2">
				<h2 className="text-2xl text-neutral-700">Two-Factor authentication</h2>
				<p>
					<b>You&apos;re all set up!</b> From now on we&apos;ll ask you for an
					authentication code to log in to your Bright products.
				</p>
				<p>
					In case you ever lose your device and need to recover your account, save these
					recovery codes in a safe place
				</p>
				<CodeOptions recoveryCodes={recoveryCodes} ref={componentRef} />
			</div>
			<div className="max-w-sm p-4 mx-auto text-center border rounded-lg shadow-md border-neutral-300">
				<h1>Your recovery codes</h1>
				<RecoveryCodes recoveryCodes={recoveryCodes} ref={componentRef} />
			</div>
		</div>
	);
};
