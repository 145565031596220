import React from 'react';

export default ({ fillColour }) => {
	return (
		<svg viewBox="0 0 140 36" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.0879 15.7084C24.8145 14.7514 23.7892 14.3413 22.0119 14.478L21.3967 14.5463C20.3031 14.6147 18.8676 14.7514 17.3638 14.9565C22.3537 11.7438 30.7613 7.43746 30.8297 7.43746L30.5563 6.34379C30.4195 5.79695 29.736 5.59189 29.1208 5.86531C27.4803 6.68556 19.4145 11.0603 15.5183 13.5894C18.3208 7.16404 20.9183 1.90074 20.9183 1.76403L19.7562 1.49062C19.2094 1.35391 18.4575 1.69568 18.1841 2.31087C17.2271 4.29315 15.1765 8.7362 12.9208 13.7944C11.622 11.7438 9.98154 10.0349 9.02457 9.07797C8.61445 8.66784 7.86255 8.80455 7.38407 9.28303L6.4271 10.24C6.4271 10.24 9.16128 12.7008 10.9385 15.5717C8.34103 15.9134 5.88027 16.2552 4.03469 16.6653C3.35115 16.802 2.87266 17.4172 2.87266 18.0324L3.00937 19.2628C4.44482 18.8527 6.63217 18.5109 9.09293 18.1691C6.90558 19.8096 4.64988 21.7236 2.32583 23.8426C1.77899 24.321 1.71064 25.0046 2.05241 25.4147L2.80431 26.235C5.40178 23.8426 7.9309 21.7236 10.3917 19.878C8.6828 24.0476 7.179 28.0805 6.35875 31.1565L6.29039 31.2932C6.01697 32.2502 7.04229 32.797 7.9309 32.1818L8.06761 32.1135C8.34103 31.9084 15.655 26.9185 19.7562 23.2274C23.9942 19.3995 25.5664 17.2122 25.0879 15.7084ZM18.3208 21.5869C15.86 23.7742 12.1005 26.5767 9.57141 28.4223C10.6651 25.2096 12.1689 21.3818 13.741 17.6223C16.5436 17.3489 19.2094 17.0755 21.055 16.9387L21.6702 16.8704C22.217 16.802 22.5588 16.8704 22.6955 16.8704C22.5588 17.2122 21.8752 18.4425 18.3208 21.5869Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M42.5408 9.22107C40.8277 9.22107 38.224 10.1166 37.2647 11.7698V3.02136L35.2776 3.22802C34.1813 3.36579 33.2905 4.33019 33.2905 5.50124V25.547H36.9906L37.2647 23.3426C38.4981 25.2714 40.4851 25.8914 42.4037 25.8914C47.1316 25.8914 50.6261 22.7915 50.6261 17.5562C50.6947 12.1143 47.2686 9.22107 42.5408 9.22107ZM42.1982 22.2404C39.7314 22.2404 37.5388 20.4494 37.5388 17.5562C37.5388 14.8008 39.6629 12.9409 42.1982 12.9409C44.7334 12.9409 46.7205 14.7319 46.7205 17.5562C46.7205 20.3117 44.7334 22.2404 42.1982 22.2404Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M52.3393 12.1143C52.3393 10.9432 53.23 9.97882 54.3264 9.84105L55.9708 9.63439L56.2449 11.4254C57.4783 9.42773 59.1228 9.15219 60.7673 9.15219C62.4117 9.15219 63.9877 9.77216 64.8785 10.6677L63.0969 14.112C62.2747 13.4231 61.521 13.0787 60.2191 13.0787C58.1635 13.0787 56.2449 14.1808 56.2449 17.074V25.4781H52.2708V12.1143H52.3393Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M70.4973 5.1568C70.4973 8.18776 65.8379 8.18776 65.8379 5.1568C65.8379 2.12583 70.4973 2.05694 70.4973 5.1568Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M70.1546 9.56543L68.1675 9.77209C67.0712 9.90986 66.1804 10.8743 66.1804 12.0453V25.5469H70.1546V9.56543V9.56543Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M88.1753 17.1429C88.1753 15.5585 87.7642 14.1119 86.4623 12.4587L88.3124 10.0477L86.8049 9.15218C85.9142 8.66999 84.8178 8.87664 84.1326 9.70327L83.8586 10.0477C82.8307 9.28996 81.1863 9.0833 80.0214 9.0833C75.8417 9.0833 71.799 11.7698 71.799 17.1429C71.799 22.1027 75.088 25.1336 80.0214 25.1336C82.8307 25.1336 84.2697 26.3736 84.2697 28.2335C84.2697 30.2312 82.0085 31.1267 79.9529 31.1267C77.6232 31.1267 75.4306 29.8179 75.4991 27.6135H71.799C71.7305 32.2289 74.7454 34.9843 80.0214 34.9843C84.0641 34.9843 88.2438 32.78 88.2438 28.3024C88.2438 26.7869 87.6957 24.3759 84.5438 23.136C86.942 21.9649 88.1753 20.0361 88.1753 17.1429ZM80.0214 21.5516C77.5547 21.5516 75.4991 19.9672 75.4991 17.1429C75.4991 14.1808 77.5547 12.7342 80.0214 12.7342C82.4881 12.7342 84.4752 14.4564 84.4752 17.1429C84.4752 19.8983 82.4881 21.5516 80.0214 21.5516Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M93.8626 11.7699C95.3015 9.90997 97.0145 9.35889 98.8646 9.35889C103.387 9.35889 105.443 12.3899 105.443 17.0741V25.4781H101.468V17.143C101.468 14.2498 99.9609 13.0098 97.8368 13.0098C95.5071 13.0098 93.8626 15.0075 93.8626 17.3496V25.4781H89.8884V5.36352C89.8884 4.19246 90.7792 3.22806 91.8755 3.09029L93.8626 2.88364V11.7699V11.7699Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M109.074 7.63671C109.074 6.46566 109.965 5.50126 111.061 5.36349L113.048 5.15683V9.6344H117.434V13.0098H112.98V19.8984C112.98 21.4138 113.802 22.1716 115.035 22.1716C115.652 22.1716 116.337 21.9649 116.954 21.6894L118.05 25.0648C116.885 25.547 115.995 25.6847 114.761 25.7536C111.267 25.8914 109.006 23.8937 109.006 19.9672V13.0787H105.991V9.70328H109.006V7.63671H109.074Z"
				fill={fillColour ?? '#0057AD'}
			/>
			<path
				d="M121.893 25.6667H120.876V16.0249H121.893V25.6667ZM124.37 16.5874C124.504 16.4534 124.669 16.3865 124.866 16.3865C125.062 16.3865 125.227 16.4534 125.361 16.5874C125.504 16.7213 125.575 16.882 125.575 17.0694C125.575 17.2658 125.508 17.431 125.375 17.5649C125.241 17.6899 125.071 17.7524 124.866 17.7524C124.669 17.7524 124.504 17.6899 124.37 17.5649C124.236 17.431 124.169 17.2658 124.169 17.0694C124.169 16.882 124.236 16.7213 124.37 16.5874ZM125.388 25.6667H124.357V19.0647H125.388V25.6667ZM131.475 24.4748L131.81 25.3185C131.31 25.6667 130.801 25.8407 130.283 25.8407C129.614 25.8407 129.082 25.6488 128.69 25.2649C128.297 24.8721 128.1 24.3186 128.1 23.6044V19.9218H126.668V19.0647H128.1V16.9623H129.132V19.0647H131.488V19.9218H129.132V23.5374C129.132 23.957 129.239 24.2874 129.453 24.5284C129.676 24.7605 129.975 24.8766 130.35 24.8766C130.725 24.8766 131.1 24.7427 131.475 24.4748ZM138.973 22.1983V22.5733H133.308C133.353 23.2875 133.607 23.8633 134.072 24.3007C134.536 24.7293 135.067 24.9435 135.665 24.9435C136.745 24.9435 137.495 24.4704 137.915 23.524L138.826 23.7383C138.567 24.4168 138.16 24.9391 137.607 25.3051C137.062 25.6622 136.415 25.8407 135.665 25.8407C134.755 25.8407 133.969 25.5194 133.308 24.8766C132.648 24.2338 132.317 23.4035 132.317 22.3858C132.317 21.3591 132.652 20.5244 133.322 19.8816C133.991 19.2299 134.795 18.904 135.732 18.904C136.634 18.904 137.388 19.212 137.995 19.828C138.611 20.4351 138.937 21.2252 138.973 22.1983ZM135.732 19.8013C135.161 19.8013 134.643 19.9843 134.179 20.3503C133.724 20.7074 133.447 21.194 133.349 21.81H137.942C137.852 21.1761 137.603 20.6851 137.192 20.3369C136.781 19.9798 136.295 19.8013 135.732 19.8013Z"
				fill={fillColour ?? '#0070E0'}
			/>
			<path
				d="M124.692 7.74335C125.469 7.74335 126.094 8.00225 126.567 8.52005C127.049 9.02892 127.29 9.70295 127.29 10.5421V14.6667H125.161V10.9974C125.161 10.6046 125.054 10.2877 124.84 10.0467C124.625 9.79668 124.344 9.6717 123.996 9.6717C123.567 9.6717 123.228 9.82793 122.978 10.1404C122.737 10.4529 122.617 10.9216 122.617 11.5465V14.6667H120.474V5.02492H122.617V8.587C123.161 8.02457 123.853 7.74335 124.692 7.74335ZM133.224 7.85048C133.403 7.85048 133.564 7.86834 133.706 7.90405L133.613 10.0467H133.224C131.68 10.0467 130.908 10.8858 130.908 12.5642V14.6667H128.765V7.94422H130.908V9.22979C131.461 8.31025 132.233 7.85048 133.224 7.85048Z"
				fill={fillColour ?? '#0057AD'}
			/>
		</svg>
	);
};
