import React from 'react';
import Input from '@brighthr/component-input';
import mobileSVG from '../assets/2FA-authenticator-app.svg';

export default ({ setCode, code, validation }) => {
	return (
		<div className="flex">
			<div className="max-w-screen-md px-8 mx-auto text-center md:text-left">
				<h2 className="mb-3 text-2xl text-neutral-700">Verify your authenticator app</h2>
				<p className="mb-3">
					To confirm you&apos;re set up correctly, enter the <b>6-digit code</b> displayed
					on your authenticator app:
				</p>
				<label className="inline-block">
					<div className="font-bold">6 digit code</div>
					<Input
						maxLength={6}
						inputSize="xl"
						onChange={(e) => {
							if (e.target.value.match(/^[0-9]*$/)) setCode(e.target.value);
						}}
						value={code}
					/>
					{validation && <div className="text-error-500">{validation}</div>}
				</label>
			</div>
			<img className="hidden md:block" src={mobileSVG} alt="mobile on authenticator app" />
		</div>
	);
};
