import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SecuritySettings from '../SecuritySettings';
import How2FAWorks from '../TwoFactorAuth/How2FAWorks';
import SingleSignOnInfo from '../SingleSignOnInfo';

const pages = [
	{
		path: '/security',
		title: 'Security settings',
		component: SecuritySettings,
	},
	{
		path: '/security/what-is-sso',
		title: 'What is Single sign-on?',
		component: SingleSignOnInfo,
	},
	{
		path: '/security/how-2fa-works',
		title: 'How 2FA works',
		component: How2FAWorks,
	},
];

const Security = () => {
	return (
		<Switch>
			{pages.map(({ path, component }) => {
				return <Route key={path} exact path={path} component={component} />;
			})}
			<Redirect exact from="/" to="/security" />
			<Redirect from="*" to="/error/not-found" />
		</Switch>
	);
};

export default Security;
